import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../shared/service/data.service' 
import { PvoKafInter, FieldData, Chart } from '../../../shared/interface/field.interface'
import * as XLSX from 'xlsx';
import * as chartData from '../../../shared/data/chart';
import { trigger, style, animate, transition } from '@angular/animations';
import { environment } from 'src/environments/environment.prod';

interface tmp{
  tableLoading: boolean;
  kafName: string;
}
@Component({
  selector: 'app-by-indicator',
  templateUrl: './by-indicator.component.html',
  styleUrls: ['./by-indicator.component.scss'],
    animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({transform: 'translateX(-100%)', opacity: 0}),
          animate('500ms', style({transform: 'translateX(0)', opacity: 1}))
        ]),
        transition(':leave', [
          style({transform: 'translateX(0)', opacity: 1}),
          animate('500ms', style({transform: 'translateX(100%)', opacity: 0}))
        ])
      ]
    )
  ],
})
export class ByIndicatorComponent implements OnInit {
  
  tizim;
  tmp: tmp = {
    tableLoading: true,
    kafName: ''
  }
  calCitiation:any = {
    current: 100,
    maxGrade: environment.otm === 'ferpi' ? 5 : 9 
    // title: ''
  };
  calCitiation1d5d2:any = {
    current: 25,
    maxGrade: 25 
  };
  field;
  pvoKaf: PvoKafInter[];
  kafedra = [];
  fakultet = [];
  showKbi: boolean = false;
  selectedLabel = "1.1";

  public kafedraSet = {
    hideSubHeader: true,
    pager: {
      display: true
    },
    actions: {
      add: false,
      edit: false,
      delete: false,
      position: 'none'
    },
    columns: {},
  };
  public fakultetSet = {
    hideSubHeader: true,
    pager: {
      display: true
    },
    actions: {
      add: false,
      edit: false,
      delete: false,
      position: 'none'
    },
    columns: {},
  };
  public settings = {
    pager: {
      display: true
    },
    actions: {
      add: false,
      edit: false,
      delete: false,
      position: 'none'
    },
    columns: {},
  };
  source = [];
  labels:any[] = []
  chartFakul: Chart = {
    type: 'Line',
    data: {
      labels: environment.fakultet,
      series: [
        [0, 0, 0, 0, 0, 0, 0, 0]
      ]
    },
    options: {
      showArea: true,
      height: '600',
      low: 0,
    }
  };
  chartKafInd: any = {
    type: 'Bar',
    data: {
      labels: [],
      series: []
    },
    options: {
      showArea: true,
      height: '600',
      low: 0,
    }
  };
  chartKaf: Chart = {
    type: 'Line',
    data: {
      labels: environment.kafedra,
      series: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      ]
    },
    options: {
      showArea: true,
      height: '600',
      width: '3000px',
      low: 0,
      axisX: {
          offset: 100
      }
    }
  };
  constructor(
    private dataService: DataService,
  ) {
     // document.addEventListener('click', (e)=>{
     //   if (e.srcElement['innerText'] && e.srcElement['innerText'].length<100) {
     //      this.showKbi = false;
     //      setTimeout(()=>{
     //        this.showKafByIndex(e.srcElement['innerText']);
     //      }, 100)
     //   }
     // })
     this.tizim = dataService.tizim;
     this.getLabels();
     this.genTableSet();

  }
  getLabels(){
    this.dataService.field$.subscribe(fields=>{
        var labels = [];
        fields.forEach((field, idx, sss)=>{
          labels.push(replaceAll(field.id, "d", "."))
          if (sss.length === idx+1) {
            this.labels = labels;
            console.log('labels', this.labels)
          }
      })
    })
  }
  changeSelectedLabel(label){
     this.selectedLabel = label;
     this.tizim = this.dataService.tizim;
     this.initialize();
     this.getLabels();
     this.genTableSet();
  }
  showKafByIndex(src){
    this.dataService.field$.subscribe(fields=>{
        this.chartKafInd.data.labels = [];
        this.chartKafInd.data.series = [];
        var series = [];
        var labels = [];
        this.tmp.kafName = src;
        fields.forEach((field, idx, sss)=>{
          this.chartKafInd.data.labels.push(replaceAll(field.id, "d", "."))
          labels.push(field.id)
          series.push(0);
          if (sss.length === idx+1) {
            console.log(labels)
            this.chartKafInd.data.series.push(series)
            this.showKafInn(src, labels);
          }
      })
    })
  }
  showKafInn(src, labels){
          this.dataService.pvoKaf$.subscribe((pvo: PvoKafInter[])=>{
            if (pvo.length) {
              var found = false;
              pvo.forEach((res, idxPvo, sss)=>{
                if (res.kafed === src) {
                    found = true;
                    labels.forEach((labelIndex, idx)=>{
                       if (res[labelIndex]) {
                         this.chartKafInd.data.series[0][idx] += res[labelIndex].grade;
                       }
                    })
                }
                if ((sss.length === idxPvo+1) && found) {
                  this.showKbi = true;
                }
              })
            }
          })
  }
  genTableSet(){
    this.settings.columns['name'] = {title: "To'liq ismi sharifi"};
    this.settings.columns['kafed'] = {title: "Kafedra"};
    this.dataService.field$.subscribe(fields=>{
      fields.forEach((field, idx, sss)=>{
        if (field) {
          this.settings.columns[`title${idx}`] = {title: replaceAll(field.id, "d", ".")};
        }
        if (sss.length === idx+1) {
          this.settings.columns['all'] = {title: 'umumiy'};
          this.dataService.pvoKaf$.subscribe((pvo: PvoKafInter[])=>{
            if (pvo.length) {
              this.getpvoKafLength(pvo, fields);
            }
          })
        }
      })
    })
  }
  getpvoKafLength(pvo: PvoKafInter[], fields){
    var pvoKafLength = {};
    pvo.forEach((res, idxPvo, sss)=>{
      if (res && res.kafed) {
        var resK=res.kafed.replace(/[ ,.]/g, "");
        pvoKafLength[`${resK}`] = (pvoKafLength[`${resK}`]?pvoKafLength[`${resK}`]:0) + 1;
      }
      if (sss.length === (idxPvo+1)) {
        // this.getMaxCitiation(pvo, fields, pvoKafLength)
        // .then((res)=>{ if(res){this.genTableSource(pvo, fields, pvoKafLength);} });
        this.genTableSource(pvo, fields, pvoKafLength);
      }
    });
  }
  // getMaxCitiation = (pvo: PvoKafInter[], fields, pvoKafLengthFir) =>{
  //   return new Promise((resolve) => {
  //     this.dataService.field$.subscribe(fields=>{
  //       // savesfsfor = 1.5 save for special formulas 
  //       pvo.forEach((res, idxPvo, sss)=>{
  //           if (res && res.kafed) {
  //             if((!res['pvo_status']) || ((res['pvo_status']) && (res['pvo_status'] === "working"))){
  
  //               fields.forEach((field, idx, aaa)=>{
  //                 if (field) {
  //                   if (field.id === "1d5") {
  
  //                     if(this.calCitiation.current < res[`${field.id}`].total){this.calCitiation.current = res[`${field.id}`].total; this.calCitiation.title = ('title' + idx)}
  //                     // arr[`title${idx}`] = res[`${field.id}`]?(((res[`${field.id}`].total * field.coeff / (environment.otm === 'fdu' ? 4 : 1))/(pvoKafLengthFir[res.kafed.replace(/[ ,.]/g, "")])).toFixed(3)):0;
  //                   }
  //                 }
  //               })
  //             }
  //           }
  
  //       })
  //     })
  //     resolve(true);
  //   });

  // }
  genTableSource(pvo: PvoKafInter[], fields, pvoKafLengthFir){
    this.dataService.field$.subscribe(fields=>{
      var fakultet = {}, kafedra = {}, pvoKafLength={}, pvoFakLength={};
      pvo.forEach((res, idxPvo, sss)=>{
          if (res && res.kafed) {
            if((!res['pvo_status']) || ((res['pvo_status']) && (res['pvo_status'] === "working"))){
              var arr = {}, sum = 0;
              arr['name'] = res.lname + ' ' + res.fname + ' ' + (res.patronymic?res.patronymic:'');
              arr['kafed'] = res.kafed;
              var resK=res.kafed.replace(/[ ,.]/g, "");
              var resF=res.fakul.replace(/[ ,.]/g, "");
              pvoKafLength[`${resK}`] = (pvoKafLength[`${resK}`]?pvoKafLength[`${resK}`]:1) + 1;      
              pvoFakLength[`${resF}`] = (pvoFakLength[`${resF}`]?pvoFakLength[`${resF}`]:1) + 1;
              fields.forEach((field, idx, aaa)=>{
                if (field && field.id === replaceAll(this.selectedLabel, ".", "d")) {
                  if(field.id==="1d5d2"&&environment.otm==="ferpi"){
                    if(res[`${field.id}`]){
                      const citiGrade = parseFloat((((res[`${field.id}`]?((res[`${field.id}`]?.total??0).toFixed(3)):0) * this.calCitiation1d5d2.maxGrade) / (this.calCitiation1d5d2.current ? this.calCitiation1d5d2.current : 1)).toFixed(3));
                      arr[`title${idx}`] = (citiGrade > this.calCitiation1d5d2.maxGrade)? this.calCitiation1d5d2.maxGrade : citiGrade;
                    }
                  }else if (field.id !== "1d5") {
                    arr[`title${idx}`] = res[`${field.id}`]?((res[`${field.id}`].grade * field.coeff).toFixed(3)):0;
                    if (field.id === "3d2") {
                      arr[`title${idx}`] = ((arr[`title${idx}`] * 100)/(this.dataService.chanAbFormulas["3d2"].grastud)).toFixed(3);
                    }
                  }else{
                    // if(this.calCitiation.current < res[`${field.id}`].total){this.calCitiation.current = res[`${field.id}`].total; this.calCitiation.title = ('title' + idx)}
                    const citiGrade = parseFloat((((res[`${field.id}`]?((res[`${field.id}`].total).toFixed(3)):0) * this.calCitiation.maxGrade) / (this.calCitiation.current ? this.calCitiation.current : 1)).toFixed(3));
                    arr[`title${idx}`] = (citiGrade > this.calCitiation.maxGrade)? this.calCitiation.maxGrade : citiGrade;
                    // arr[`title${idx}`] = res[`${field.id}`]?((res[`${field.id}`].total).toFixed(3)):0;
                    // arr[`title${idx}`] = res[`${field.id}`]?(((res[`${field.id}`].total * field.coeff / (environment.otm === 'fdu' ? 4 : 1))/(pvoKafLengthFir[res.kafed.replace(/[ ,.]/g, "")])).toFixed(3)):0;
                  }
                  sum += parseFloat(arr[`title${idx}`]);
                }
                if (aaa.length === (idx+1)) {
                  arr['all'] = sum.toFixed(3);
                  this.source.push(arr);
                  var trK=res.kafed.replace(/[ ,.]/g, "");
                  var trF=res.fakul.replace(/[ ,.]/g, "");
                  if(!isNaN(sum)){
                    fakultet[`${trF}`] = (fakultet[`${trF}`]?fakultet[`${trF}`]:0) + sum;
                    kafedra[`${trK}`] = (kafedra[`${trK}`]?kafedra[`${trK}`]:0) + sum;
                  }
                }
              })
            }
          }
          if (sss.length === (idxPvo+1)) {
              var arrKaf={}, arrKafChart=[], arrFak={}, arrFakChart=[], tizimCount = 0
              this.tizim.forEach((tiz, idxKaf, sssKaf)=>{
                tiz.kafedralar.forEach(t=>{
                  this.kafedraSet.columns[`title${tizimCount}`] = {title: t};
                  let pvoKafLengthDivide=1
                  const pvoKafLengthDynamic = this.dataService.areas?.find(a=>a.name.replace(/[ ,.]/g, "")===t.replace(/[ ,.]/g, ""))
                  if(pvoKafLengthDynamic){
                    pvoKafLengthDivide = pvoKafLengthDynamic.numOfEmployees?pvoKafLengthDynamic.numOfEmployees:1;
                  }else{
                    pvoKafLengthDivide = (pvoKafLength[`${t.replace(/[ ,.]/g, "")}`]?(pvoKafLength[`${t.replace(/[ ,.]/g, "")}`]-1):1);
                  }
                  arrKaf[`title${tizimCount}`] = (((kafedra[`${t.replace(/[ ,.]/g, "")}`]?kafedra[`${t.replace(/[ ,.]/g, "")}`]:0))/pvoKafLengthDivide).toFixed(3);
                  arrKafChart.push(arrKaf[`title${tizimCount}`])
                  tizimCount++;
                })
                this.fakultetSet.columns[`title${idxKaf}`] = {title: tiz.fakultet};
                let pvoFakLengthDivide = this.dataService.areas?.reduce((acc, a)=>{
                  if(a.departmentName.replace(/[ ,.]/g, "")===tiz.fakultet.replace(/[ ,.]/g, "")){
                    acc=acc+a.numOfEmployees??1;
                  }
                  return acc;
                }, 0)
                if(!pvoFakLengthDivide){
                  pvoFakLengthDivide=(pvoFakLength[`${tiz.fakultet.replace(/[ ,.]/g, "")}`]?(pvoFakLength[`${tiz.fakultet.replace(/[ ,.]/g, "")}`]-1):1)
                }
                arrFak[`title${idxKaf}`] = (((fakultet[`${tiz.fakultet.replace(/[ ,.]/g, "")}`]?(fakultet[`${tiz.fakultet.replace(/[ ,.]/g, "")}`]):0))/pvoFakLengthDivide).toFixed(3);
                arrFakChart.push(arrFak[`title${idxKaf}`])
                if (sssKaf.length === idxKaf+1) {
                  this.kafedra.push(arrKaf);
                  this.fakultet.push(arrFak);
                  this.chartFakul.data.series = [arrFakChart];
                  this.chartKaf.data.series = [arrKafChart];
                  // this.dataService.rating$.next({chartFakul: this.chartFakul, chartKaf: this.chartKaf, kafedraSet: this.kafedraSet, kafedra: this.kafedra, fakultetSet: this.fakultetSet, fakultet: this.fakultet, settings: this.settings, source: this.source});
                }
              })
              // this.source.map(pvo=> { if( pvo && pvo[this.calCitiation.title]){ pvo[this.calCitiation.title] = ((parseFloat(pvo[this.calCitiation.title]) * 9)/ (this.calCitiation.current ? this.calCitiation.current : 1)).toFixed(3) }});
              this.tmp.tableLoading = false;
          }
      })
    })
  }

  fileName = 'ExcelSheet.xlsx';
  exportexcel(id){
     /* table id is passed over here */   
      this.tmp.tableLoading = true;
      if (id === "excelTableFakultet") {
        this.fakultetSet.pager.display = false;
      }
      if (id === "excelTableKafedra") {
        this.kafedraSet.pager.display = false;
      }
      if (id === "excelTablePvo") {
        this.settings.pager.display = false;
      }
      setTimeout(()=>{
        this.tmp.tableLoading = false;
        setTimeout(()=>{

            // this.excelService.exportAsExcelFile(document.getElementById('excelTable').children[0]);
             // console.log('element', element)
             const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(document.getElementById(`${id}`).children[0]);

             const wb: XLSX.WorkBook = XLSX.utils.book_new();
             XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

             XLSX.writeFile(wb, this.fileName);
        }, 1000)
      }, 1000)
    
  }
  ngOnInit() { }
  initialize(){
    this.tmp = {
      tableLoading: true,
      kafName: ''
    }
    this.calCitiation = {
      current: 100,
      // title: ''
    };

    this.field = '';
    this.pvoKaf = [];
    this.kafedra = [];
    this.fakultet = [];
    this.showKbi = false;

    this.kafedraSet = {
      hideSubHeader: true,
      pager: {
        display: true
      },
      actions: {
        add: false,
        edit: false,
        delete: false,
        position: 'none'
      },
      columns: {},
    };
    this.fakultetSet = {
      hideSubHeader: true,
      pager: {
        display: true
      },
      actions: {
        add: false,
        edit: false,
        delete: false,
        position: 'none'
      },
      columns: {},
    };
    this.settings = {
      pager: {
        display: true
      },
      actions: {
        add: false,
        edit: false,
        delete: false,
        position: 'none'
      },
      columns: {},
    };
    this.source = [];
    this.labels = []
    this.chartFakul = {
      type: 'Line',
      data: {
        labels: environment.fakultet,
        series: [
          [0, 0, 0, 0, 0, 0, 0, 0]
        ]
      },
      options: {
        showArea: true,
        height: '600',
        low: 0,
      }
    };
    this.chartKafInd = {
      type: 'Bar',
      data: {
        labels: [],
        series: []
      },
      options: {
        showArea: true,
        height: '600',
        low: 0,
      }
    };
    this.chartKaf = {
      type: 'Line',
      data: {
        labels: environment.kafedra,
        series: [
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        ]
      },
      options: {
        showArea: true,
        height: '600',
        width: '3000px',
        low: 0,
        axisX: {
            offset: 100
        }
      }
    };
  }
}
function replaceAll(str, from, to){
    var n = '';
    if (str) {
      for(let g of str){
        if (g === from) {
          g = to;  
        }
        n += g;
      }
    }
    return n;
}