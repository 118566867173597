import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../shared/service/data.service' 
import { PvoKafInter, FieldData, Chart } from '../../../shared/interface/field.interface'
import * as XLSX from 'xlsx';
import * as chartData from '../../../shared/data/chart';
import { environment } from 'src/environments/environment.prod';

interface tmp{
  tableLoading: boolean;
}
@Component({
  selector: 'app-teachers',
  templateUrl: './teachers.component.html',
  styleUrls: ['./teachers.component.scss']
})
export class TeachersComponent implements OnInit {
  
  tizim;
  tmp: tmp = {
    tableLoading: true
  }
  calCitiation:any = {
    current: 100,
    maxGrade: environment.otm === 'ferpi' ? 5 : 9 
  };
  calCitiation1d5d2:any = {
    current: 25,
    maxGrade: 25 
  };
  field;
  pvoKaf: PvoKafInter[];
  kafedra = [];
  fakultet = [];
  public kafedraSet = {
    hideSubHeader: true,
    pager: {
      display: true
    },
    actions: {
      add: false,
      edit: false,
      delete: false,
      position: 'none'
    },
    columns: {},
  };
  public fakultetSet = {
    hideSubHeader: true,
    pager: {
      display: true
    },
    actions: {
      add: false,
      edit: false,
      delete: false,
      position: 'none'
    },
    columns: {},
  };
  public settings = {
    pager: {
      display: true
    },
    actions: {
      add: false,
      edit: false,
      delete: false,
      position: 'none'
    },
    columns: {},
  };
  source = [];
  chartFakul: Chart = {
    type: 'Line',
    data: {
      labels: environment.fakultet,
      series: [
        [0, 0, 0, 0, 0, 0, 0, 0]
      ]
    },
    options: {
      showArea: true,
      height: '600',
      low: 0,
    }
  };
  chartKaf: Chart = {
    type: 'Line',
    data: {
      labels: environment.kafedra,
      series: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      ]
    },
    options: {
      showArea: true,
      height: '600',
      width: '3000px',
      low: 0,
      axisX: {
          offset: 100
      }
    }
  };
  constructor(
    private dataService: DataService,
  ) {
     dataService.rating$.subscribe(rating=>{
      if (rating) {
        // this.chartFakul =  rating.chartFakul;
        // this.chartKaf = rating.chartKaf;
        // this.kafedraSet = rating.kafedraSet;
        // this.kafedra = rating.kafedra;
        // this.fakultetSet = rating.fakultetSet;
        // this.fakultet = rating.fakultet;
        this.settings = rating.settings;
        this.source = rating.source;
        this.tmp.tableLoading = false;
      }else{
        this.tizim = dataService.tizim;
        this.genTableSet();
      }
    })
  }
  genTableSet(){
    this.settings.columns['name'] = {title: "To'liq ismi sharifi"};
    this.settings.columns['kafed'] = {title: "Kafedra"};
    this.dataService.field$.subscribe(fields=>{
      fields.forEach((field, idx, sss)=>{
        if (field) {
          this.settings.columns[`title${idx}`] = {title: replaceAll(field.id, "d", ".")};
        }
        if (sss.length === idx+1) {
          this.settings.columns['all'] = {title: 'umumiy'};
          this.dataService.pvoKaf$.subscribe((pvo: PvoKafInter[])=>{
            if (pvo.length) {
              this.getpvoKafLength(pvo, fields);
            }
          })
        }
      })
    })
  }
  getpvoKafLength(pvo: PvoKafInter[], fields){
    var pvoKafLength = {};
    pvo.forEach((res, idxPvo, sss)=>{
      if (res && res.kafed) {
        var resK=res.kafed.replace(/[ ,.]/g, "");
        pvoKafLength[`${resK}`] = (pvoKafLength[`${resK}`]?pvoKafLength[`${resK}`]:0) + 1;
      }
      if (sss.length === (idxPvo+1)) {
        // this.getMaxCitiation(pvo, fields, pvoKafLength)
        // .then((res)=>{ if(res){this.genTableSource(pvo, fields, pvoKafLength);} });
        this.genTableSource(pvo, fields, pvoKafLength);
      }
    });
  }
  // getMaxCitiation = (pvo: PvoKafInter[], fields, pvoKafLengthFir) =>{
  //   return new Promise((resolve) => {
  //     this.dataService.field$.subscribe(fields=>{
  //       // savesfsfor = 1.5 save for special formulas 
  //       pvo.forEach((res, idxPvo, sss)=>{
  //           if (res && res.kafed) {
  //             if((!res['pvo_status']) || ((res['pvo_status']) && (res['pvo_status'] === "working"))){
  
  //               fields.forEach((field, idx, aaa)=>{
  //                 if (field) {
  //                   if (field.id === "1d5") {
  
  //                     if(this.calCitiation.current < res[`${field.id}`].total){this.calCitiation.current = res[`${field.id}`].total; this.calCitiation.title = ('title' + idx)}
  //                     // arr[`title${idx}`] = res[`${field.id}`]?(((res[`${field.id}`].total * field.coeff / (environment.otm === 'fdu' ? 4 : 1))/(pvoKafLengthFir[res.kafed.replace(/[ ,.]/g, "")])).toFixed(3)):0;
  //                   }
  //                 }
  //               })
  //             }
  //           }
  
  //       })
  //     })
  //     resolve(true);
  //   });

  // }

  genTableSource(pvo: PvoKafInter[], fields, pvoKafLengthFir){
    this.dataService.field$.subscribe(fields=>{
      // savesfsfor = 1.5 save for special formulas 
      var fakultet = {}, kafedra = {}, pvoKafLength={}, pvoFakLength={};
      pvo.forEach((res, idxPvo, sss)=>{
          if (res && res.kafed) {
            // console.log(res)
            if((!res['pvo_status']) || ((res['pvo_status']) && (res['pvo_status'] === "working"))){
              var arr = {}, sum = 0;
              arr['name'] = res.lname + ' ' + res.fname + ' ' + (res.patronymic?res.patronymic:'');
              arr['kafed'] = res.kafed;
              var resK=res.kafed.replace(/[ ,.]/g, "");
              var resF=res.fakul.replace(/[ ,.]/g, "");
              pvoKafLength[`${resK}`] = (pvoKafLength[`${resK}`]?pvoKafLength[`${resK}`]:1) + 1;
              pvoFakLength[`${resF}`] = (pvoFakLength[`${resF}`]?pvoFakLength[`${resF}`]:1) + 1;
              fields.forEach((field, idx, aaa)=>{
                if (field) {
                  if(field.id==="1d5d2"&&environment.otm==="ferpi"){
                    if(res[`${field.id}`]){
                      const citiGrade = parseFloat((((res[`${field.id}`]?((res[`${field.id}`]?.total??0).toFixed(3)):0) * this.calCitiation1d5d2.maxGrade) / (this.calCitiation1d5d2.current ? this.calCitiation1d5d2.current : 1)).toFixed(3));
                      arr[`title${idx}`] = (citiGrade > this.calCitiation1d5d2.maxGrade)? this.calCitiation1d5d2.maxGrade : citiGrade;
                    }
                  }else if(field.id !== "1d5") {
                    arr[`title${idx}`] = res[`${field.id}`]?((res[`${field.id}`].grade * field.coeff).toFixed(3)):0;
                    if (field.id === "3d2") {
                      arr[`title${idx}`] = ((arr[`title${idx}`] * 100)/(this.dataService.chanAbFormulas["3d2"].grastud)).toFixed(3);
                    }
                  }else{
                    // if(this.calCitiation.current < res[`${field.id}`].total){this.calCitiation.current = res[`${field.id}`].total; this.calCitiation.title = ('title' + idx)}
                    const citiGrade = parseFloat((((res[`${field.id}`]?((res[`${field.id}`].total).toFixed(3)):0) * this.calCitiation.maxGrade) / (this.calCitiation.current ? this.calCitiation.current : 1)).toFixed(3));
                    arr[`title${idx}`] = (citiGrade > this.calCitiation.maxGrade)? this.calCitiation.maxGrade : citiGrade;

                    // arr[`title${idx}`] = res[`${field.id}`]?(((res[`${field.id}`].total * field.coeff / (environment.otm === 'fdu' ? 4 : 1))/(pvoKafLengthFir[res.kafed.replace(/[ ,.]/g, "")])).toFixed(3)):0;
                  }
                  sum += parseFloat(arr[`title${idx}`]);
                }
                if (aaa.length === (idx+1)) {
                  arr['all'] = sum.toFixed(3);
                  this.source.push(arr);
                  var trK=res.kafed.replace(/[ ,.]/g, "");
                  var trF=res.fakul.replace(/[ ,.]/g, "");
                  if(!isNaN(sum)){
                    fakultet[`${trF}`] = (fakultet[`${trF}`]?fakultet[`${trF}`]:0) + sum;
                    kafedra[`${trK}`] = (kafedra[`${trK}`]?kafedra[`${trK}`]:0) + sum;
                  }
                }
              })
            }
          }
          if (sss.length === (idxPvo+1)) {
              var arrKaf={}, arrKafChart=[], arrFak={}, arrFakChart=[], tizimCount = 0;
              this.tizim.forEach((tiz, idxKaf, sssKaf)=>{
                tiz.kafedralar.forEach(t=>{
                  this.kafedraSet.columns[`title${tizimCount}`] = {title: t};
                  let pvoKafLengthDivide=1
                  const pvoKafLengthDynamic = this.dataService.areas?.find(a=>a.name.replace(/[ ,.]/g, "")===t.replace(/[ ,.]/g, ""))
                  if(pvoKafLengthDynamic){
                    pvoKafLengthDivide = pvoKafLengthDynamic.numOfEmployees?pvoKafLengthDynamic.numOfEmployees:1;
                  }else{
                    pvoKafLengthDivide = (pvoKafLength[`${t.replace(/[ ,.]/g, "")}`]?(pvoKafLength[`${t.replace(/[ ,.]/g, "")}`]-1):1);
                  }
                  // console.log(pvoKafLengthStatic, t)
                  arrKaf[`title${tizimCount}`] = (((kafedra[`${t.replace(/[ ,.]/g, "")}`]?kafedra[`${t.replace(/[ ,.]/g, "")}`]:0))/pvoKafLengthDivide).toFixed(3);
                  arrKafChart.push(arrKaf[`title${tizimCount}`])
                  tizimCount++;
                })
                this.fakultetSet.columns[`title${idxKaf}`] = {title: tiz.fakultet};
                let pvoFakLengthDivide = this.dataService.areas?.reduce((acc, a)=>{
                  if(a.departmentName.replace(/[ ,.]/g, "")===tiz.fakultet.replace(/[ ,.]/g, "")){
                    acc=acc+a.numOfEmployees??1;
                  }
                  return acc;
                }, 0)
                if(!pvoFakLengthDivide){
                  pvoFakLengthDivide=(pvoFakLength[`${tiz.fakultet.replace(/[ ,.]/g, "")}`]?(pvoFakLength[`${tiz.fakultet.replace(/[ ,.]/g, "")}`]-1):1)
                }
                arrFak[`title${idxKaf}`] = (((fakultet[`${tiz.fakultet.replace(/[ ,.]/g, "")}`]?(fakultet[`${tiz.fakultet.replace(/[ ,.]/g, "")}`]):0))/pvoFakLengthDivide).toFixed(3);
                arrFakChart.push(arrFak[`title${idxKaf}`])
                if (sssKaf.length === idxKaf+1) {
                  this.kafedra.push(arrKaf);
                  this.fakultet.push(arrFak);
                  this.chartFakul.data.series = [arrFakChart];
                  this.chartKaf.data.series = [arrKafChart];
                  this.dataService.rating$.next({chartFakul: this.chartFakul, chartKaf: this.chartKaf, kafedraSet: this.kafedraSet, kafedra: this.kafedra, fakultetSet: this.fakultetSet, fakultet: this.fakultet, settings: this.settings, source: this.source});
                }
              })
              // console.log('calCitiation', this.calCitiation)
              // this.source.map(pvo=> { if( pvo && pvo[this.calCitiation.title]){ pvo[this.calCitiation.title] = ((parseFloat(pvo[this.calCitiation.title]) * 9)/ (this.calCitiation.current ? this.calCitiation.current : 1)).toFixed(3); 
              //                         pvo['all'] = (parseFloat(pvo['all']) +  parseFloat(pvo[this.calCitiation.title])).toFixed(3); }});
              this.tmp.tableLoading = false;
          }
      })
    })
  }

  fileName = 'ExcelSheet.xlsx';
  exportexcel(id){
     /* table id is passed over here */   
      this.tmp.tableLoading = true;
      if (id === "excelTableFakultet") {
        this.fakultetSet.pager.display = false;
      }
      if (id === "excelTableKafedra") {
        this.kafedraSet.pager.display = false;
      }
      if (id === "excelTablePvo") {
        this.settings.pager.display = false;
      }
      setTimeout(()=>{
        this.tmp.tableLoading = false;
        setTimeout(()=>{

            // this.excelService.exportAsExcelFile(document.getElementById('excelTable').children[0]);
             const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(document.getElementById(`${id}`).children[0]);

             const wb: XLSX.WorkBook = XLSX.utils.book_new();
             XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

             XLSX.writeFile(wb, this.fileName);
        }, 1000)
      }, 1000)
    
  }
  ngOnInit() { }

}
function replaceAll(str, from, to){
    var n = '';
    if (str) {
      for(let g of str){
        if (g === from) {
          g = to;  
        }
        n += g;
      }
    }
    return n;
}