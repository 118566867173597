import { Injectable, HostListener, Inject } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { WINDOW } from "./windows.service";
import { IndexFormsService } from './index-forms.service'; 
import { DataService } from './data.service'; 
import { AuthService } from './auth.service'; 
import { environment } from 'src/environments/environment.prod'; 
// Menu
export interface Menu {
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
	indexId?: string;
	status?: string;
	titleExp?: string;
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	public screenWidth: any
	public collapseSidebar: boolean = false

	constructor(
		@Inject(WINDOW) private window,
		private indexFormsService: IndexFormsService,
		private dataService: DataService,
		private authService: AuthService
	) {
		this.onResize();
		if (this.screenWidth < 991) {
			this.collapseSidebar = true
		}
		if(this.dataService.user.roles.find(r=>r==='ADMIN')){
			this.getMenuItemsAdmin();
		}else if(this.dataService.user.roles.find(r=>r==='PVO')){
			this.getMenuItemsPvo();

		}
	}

	// Windows width
	@HostListener("window:resize", ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}
	getMenuItemsPvo(){
		this.MENUITEMS = [
			{
				title: 'Uy', path: '/home', icon: 'home', type: 'link', active: false
			},
			{
				title: 'Ma\'lumotlar', icon: 'file-text', type: 'sub', active: false, children: []
			},
			{
				title: 'E\'lonlar', path: '/announce', icon: 'twitch', type: 'link', active: false
			},
			{
				title: 'Tekshirilayotgan', path: '/being-checked', icon: 'help-circle', type: 'link', active: false
			},
			{
				title: 'Rad etilgan', path: '/rejected-field', icon: 'thumbs-down', type: 'link', active: false
			},
			{
				title: 'Baholash', icon: 'sidebar', type: 'sub', active: false, children: [
					{ path: '/rating/fakul-table', title: 'Fakultet', type: 'link' },
					{ path: '/rating/kaf-table', title: 'Kafedra', type: 'link' },
					{ path: '/rating/teachers', title: 'O\'qituvchilar', type: 'link' },
					{ path: '/rating/by-indicator', title: 'Indikator bo‘yicha', type: 'link' },
				]
			}
		]
		this.dataService.field$.subscribe(fields=>{
			this.MENUITEMS = [
				{
					title: 'Uy', path: '/home', icon: 'home', type: 'link', active: false
				},
				{
					title: 'Ma\'lumotlar', icon: 'file-text', type: 'sub', active: false, children: []
				},
				{
					title: 'E\'lonlar', path: '/announce', icon: 'twitch', type: 'link', active: false
				},
				{
					title: 'Tekshirilayotgan', path: '/being-checked', icon: 'help-circle', type: 'link', active: false
				},
				{
					title: 'Rad etilgan', path: '/rejected-field', icon: 'thumbs-down', type: 'link', active: false
				},
				{
					title: 'Baholash', icon: 'sidebar', type: 'sub', active: false, children: [
						{ path: '/rating/fakul-table', title: 'Fakultet', type: 'link' },
						{ path: '/rating/kaf-table', title: 'Kafedra', type: 'link' },
						{ path: '/rating/teachers', title: 'O\'qituvchilar', type: 'link' },
						{ path: '/rating/by-indicator', title: 'Indikator bo‘yicha', type: 'link' },
					]
				}
			]
			fields.forEach(field=>{
				if (field) {
					this.MENUITEMS[1].children.push({title: replaceAll((field.id), "d", "."), path: `/indexes-data-pvo/${field.id}`, type: 'link', titleExp: field.title})
					this.MENUITEMS.push({title: replaceAll((field.id), "d", "."), path: `/indexes/${field.id}`, icon: 'clipboard', type: 'link', active: false, status: (field.status && field.status.title === 'closed')?'closed':'open', titleExp: field.title})
				}
			})
			this.items.next(this.MENUITEMS);
		})
		this.items.next(this.MENUITEMS);
	}
	getMenuItemsAdmin(){
		this.MENUITEMS = [
			{
				title: 'Uy', path: '/home-admin', icon: 'home', type: 'link', active: false
			},
			{
				title: 'Ma\'lumotlar', icon: 'file-text', type: 'sub', active: false, children: []
			},
			{
				title: 'Baholash', icon: 'sidebar', type: 'sub', active: false, children: [
					{ path: '/ratinga/fakul-table', title: 'Fakultet', type: 'link' },
					{ path: '/ratinga/kaf-table', title: 'Kafedra', type: 'link' },
					{ path: '/ratinga/teachers', title: 'O\'qituvchilar', type: 'link' },
					{ path: '/ratinga/statistics', title: 'Statistika', type: 'link' },
					{ path: '/ratinga/by-indicator', title: 'Indikator bo‘yicha', type: 'link' },
				]
			},
			{
				title: 'Tizim', icon: 'sidebar', type: 'sub', active: false, children: [
					{ path: '/system/departments', title: 'Fakultet', type: 'link' },
					{ path: '/system/area', title: 'Kafedra', type: 'link' },
				]
			}
		]
		switch(environment.otm){
			case "ferpi":
				this.MENUITEMS.push(
					{
						title: 'Tarix', icon: 'clock', type: 'sub', active: false, children: [
							{ path: 'http://ferpi.tsnqb.uz/2021/', title: '2021', type: 'extTabLink' },
							{ path: 'http://ferpi.tsnqb.uz/2022/', title: '2022', type: 'extTabLink' },
							{ path: 'http://ferpi.tsnqb.uz/2023/', title: '2023', type: 'extTabLink' },
						]
					}
				)
				break;
			case "tatuff":
				this.MENUITEMS.push({
						title: 'Tarix', icon: 'clock', type: 'sub', active: false, children: [
							{ path: 'https://tsnqb.fbtuit.uz/db_tatuff/2022', title: '2022', type: 'extTabLink' },
						]
				})
				break;
			case 'fdu':
				this.MENUITEMS.push({
					title: 'Tarix', icon: 'clock', type: 'sub', active: false, children: [
						{ path: 'https://kpi.fdu.uz/db_fdu/2022/', title: '2022', type: 'extTabLink' },
					]
				})	
				break;
			default:
				break;
		}

		
		this.dataService.field$.subscribe(fields=>{
			this.MENUITEMS = [
				{
					title: 'Uy', path: '/home-admin', icon: 'home', type: 'link', active: false
				},
				{
					title: 'Ma\'lumotlar', icon: 'file-text', type: 'sub', active: false, children: []
				},
				{
					title: 'E‘lon', icon: 'twitch', type: 'sub', active: false, children: [
						{ path: '/announce-admin/list-announce', title: 'ro‘yhati', type: 'link' },
						{ path: '/announce-admin/create-announce', title: 'q‘oshish', type: 'link' },
					]
				},
				{
					title: 'Tekshirish', path: '/check-ind', icon: 'check-square', type: 'link', active: false
				},
				{
					title: 'Indikator', icon: 'file-plus', type: 'sub', active: false, children: [
						{ path: '/create-index', title: 'yaratish', type: 'link' },
						{ path: '/ctrl-indexes', title: 'boshqarish', type: 'link' },
					]
				},
				{
					title: 'O‘qituvchilar', icon: 'user-plus', type: 'sub', active: false, children: [
						{ path: '/teachers/list', title: 'ro\'yxati', type: 'link' },
						{ path: '/teachers/create', title: 'yaratish', type: 'link' },
						// { path: '/users/register-new', title: 'Yangi foydalanuvchi', type: 'link' },
					]
				},
				// {
				// 	title: 'Foydalanuvchilar', path: '/users', icon: 'user', type: 'link', active: false
				// },
				{
					title: 'Baholash', icon: 'sidebar', type: 'sub', active: false, children: [
						{ path: '/ratinga/fakul-table', title: 'Fakultet', type: 'link' },
						{ path: '/ratinga/kaf-table', title: 'Kafedra', type: 'link' },
						{ path: '/ratinga/teachers', title: 'O\'qituvchilar', type: 'link' },
						{ path: '/ratinga/statistics', title: 'Statistika', type: 'link' },
						{ path: '/ratinga/by-indicator', title: 'Indikator bo‘yicha', type: 'link' },
					]
				},
				{
					title: 'Tizim', icon: 'sidebar', type: 'sub', active: false, children: [
						{ path: '/system/departments', title: 'Fakultet', type: 'link' },
						{ path: '/system/area', title: 'Kafedra', type: 'link' },
					]
				}
			]
			if(environment.otm!=='fjsti'){
				this.MENUITEMS.push(
					{
						title: 'Foydalanuvchilar', icon: 'user', type: 'sub', active: false, children: [
							// { path: '/users/list', title: 'ro\'yxati', type: 'link' },
							{ path: '/users/create', title: 'yaratish', type: 'link' },
							// { path: '/users/register-new', title: 'Yangi foydalanuvchi', type: 'link' },
						]
					}
				)
			}
			switch(environment.otm){
				case "ferpi":
					this.MENUITEMS.push(
						{
							title: 'Tarix', icon: 'clock', type: 'sub', active: false, children: [
								{ path: 'http://ferpi.tsnqb.uz/2021/', title: '2021', type: 'extTabLink' },
								{ path: 'http://ferpi.tsnqb.uz/2022/', title: '2022', type: 'extTabLink' },
								{ path: 'http://ferpi.tsnqb.uz/2023/', title: '2023', type: 'extTabLink' },
							]
						}
					)
					break;
				case "tatuff":
					this.MENUITEMS.push({
							title: 'Tarix', icon: 'clock', type: 'sub', active: false, children: [
								{ path: 'https://tsnqb.fbtuit.uz/2022', title: '2022', type: 'extTabLink' },
							]
					})
					break;
				case 'fdu':
					this.MENUITEMS.push({
						title: 'Tarix', icon: 'clock', type: 'sub', active: false, children: [
							{ path: 'https://kpi.fdu.uz/2022/', title: '2022', type: 'extTabLink' },
						]
					})	
					break;
				default:
					break;
			}
			
			fields.forEach(field=>{
				if (field) {
					this.MENUITEMS[1].children.push({title: replaceAll((field.id), "d", "."), path: `/indexes-data/${field.id}`, type: 'link', titleExp: field.title})
				}
			})
			this.items.next(this.MENUITEMS);
		})
		this.items.next(this.MENUITEMS);
	}

	MENUITEMS: Menu[] = []
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);


}
function replaceAll(str, from, to){
    var n = '';
    if (str) {
	    for(let g of str){
	      if (g === from) {
	        g = to;  
	      }
	      n += g;
	    }
    }
    return n;
}