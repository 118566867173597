//ferpi prod///////////
export const environment_ferpi = {
  otm: 'ferpi',
  firebase: {
    email: 'obidov.bekzod94@gmail.com',
    p: '7^^VnW5&9vY97!xa',
  },
  a: {
    email: 'O_A@',
    p: 'osha',
  },
  s: {
    l: 'tsnqbuz',
    p: 'MzK6Lc2vf75!)B ser3',
  },
  production: true,
  http_auth: '5KWBffF64Q7v6J9fLA4K',
  otm_name: "Ta'lim sifatini nazorat qilish bo'limi",
  firebaseConfig: {
    // pvorey
    apiKey: 'AIzaSyBmzKSP6a7bHTJWRZTqRgF3u8ucSJB2_fg',
    authDomain: 'pvorey.firebaseapp.com',
    databaseURL:
      'https://pvorey-default-rtdb.asia-southeast1.firebasedatabase.app',
    projectId: 'pvorey',
    storageBucket: 'pvorey.appspot.com',
    messagingSenderId: '975847085157',
    appId: '1:975847085157:web:5008868ba373c480ffa28c',
    measurementId: 'G-GRE7FMXH99',
  },
  http: {
    socket: {
      path: 'ws://ferpi.tsnqb.uz/socket.io',
    },
    editArea: {
      path: 'https://ferpi.tsnqb.uz/api/system/area',
    },
    addArea: {
      path: 'https://ferpi.tsnqb.uz/api/system/area',
    },
    deleteArea: {
      path: 'https://ferpi.tsnqb.uz/api/system/area',
    },
    removeDepartment: {
      path: 'https://ferpi.tsnqb.uz/api/system/department',
    },
    editDepartment: {
      path: 'https://ferpi.tsnqb.uz/api/system/department',
    },
    getDepartmentAArea: {
      path: 'https://ferpi.tsnqb.uz/api/system/getAll',
    },
    addDepartment: {
      path: 'https://ferpi.tsnqb.uz/api/system/department',
    },
    userRegister: {
      path: 'https://ferpi.tsnqb.uz/api/auth/registration',
    },
    recoverPass: {
      path: 'https://ferpi.tsnqb.uz/api/mailing/send-mail',
    },
    allRoles: {
      path: 'https://ferpi.tsnqb.uz/api/auth/login',
    },
    removeRole: {
      path: 'https://ferpi.tsnqb.uz/api/auth/login',
    },
    setRole: {
      path: 'https://ferpi.tsnqb.uz/api/auth/login',
    },
    removeTeacher: {
      path: 'https://ferpi.tsnqb.uz/api/auth/login',
    },
    allUsers: {
      path: 'https://ferpi.tsnqb.uz/api/users/all',
    },
    updateUser: {
      path: 'https://ferpi.tsnqb.uz/api/auth/login',
    },
    deleteUser: {
      path: 'https://ferpi.tsnqb.uz/api/auth/login',
    },
    login: {
      path: 'https://ferpi.tsnqb.uz/api/auth/login',
    },
    autologin: {
      path: 'https://ferpi.tsnqb.uz/api/auth/autologin',
    },
    get: {
      //https://tsnqb.ferpi.uz/db_pvorey_test //https://2shahar.uz/tsnqb/get_data.php
      path: 'https://ferpi.tsnqb.uz/db/2024/get_data.php',
      file: 'pvorey.txt',
    },
    delete_only_inform_file: {
      path: 'https://ferpi.tsnqb.uz/db/2024/delete_only_inform_file.php',
    },
    getFieldsInform: {
      path: 'https://ferpi.tsnqb.uz/db/2024/get_fields_inform.php',
    },
    setInc: {
      path: 'https://ferpi.tsnqb.uz/db/2024/set_data_inc.php',
      file: 'pvorey.txt',
    },
    del: {
      path: 'https://ferpi.tsnqb.uz/db/2024/del_data.php',
      file: 'pvorey.txt',
    },
    setIncDelUpd: {
      path: 'https://ferpi.tsnqb.uz/db/2024/set_inc_del_upd.php',
      file: 'pvorey.txt',
    },
    remDocDownGrade: {
      path: 'https://ferpi.tsnqb.uz/db/2024/rem_doc_with_down_grade.php',
    },
    googleScholarCited: {
      path: 'https://ferpi.tsnqb.uz/db/2024/google_scholar.php',
    },
    googleScholarCitedAll: {
      path: 'https://ferpi.tsnqb.uz/db/2024/google_scholar_all.php',
    },
    setNewPvo: {
      path: 'https://ferpi.tsnqb.uz/db/2024/set_new_pvo.php',
    },
    removePvo: {
      path: 'https://ferpi.tsnqb.uz/db/2024/remove_pvo.php',
    },
    controlIndexStatus: {
      path: 'https://ferpi.tsnqb.uz/db/2024/control_index_status.php',
    },
    createAnnounce: {
      path: 'https://ferpi.tsnqb.uz/db/2024/create_announce.php',
    },
    getAnnounces: {
      path: 'https://ferpi.tsnqb.uz/db/2024/get_announces.php',
    },
    removeAnnounce: {
      path: 'https://ferpi.tsnqb.uz/db/2024/remove_announce.php',
    },
    editUserAccaunt: {
      path: 'https://ferpi.tsnqb.uz/db/2024/edit_user_account.php',
    },
    createIndex: {
      path: 'https://ferpi.tsnqb.uz/db/2024/create_index.php',
    },
  },
  tizim: [
    {
      fakultet: 'Mexanika - mashinasozlik',
      kafedralar: [
        'Mashinasozlik texnologiyasi, jihozlari va mashinasozlikda ishlab chiqarishni avtomatlashtirish',
        'Oliy matematika',
        'Tadbiqiy mexanika',
        'Texnologik mashinalar va jihozlar',
        'Yer usti transport tizimlari va ularni ekspluatatsiyasi',
      ],
    },
    {
      fakultet: 'Energetika',
      kafedralar: [
        'Elektr energetika',
        'Elektr texnikasi, elektr mexanikasi va elektr texnologiyalari',
        'Fizika',
        'Chizma geometriya va muhandislik grafikasi',
      ],
    },
    {
      fakultet: 'Kimyo texnologiya',
      kafedralar: [
        'Kimyo va kimyoviy texnologiya',
        'Neft va neft-gazni qayta ishlash texnologiyasi',
        'Oziq-ovqatlar texnologiyasi',
      ],
    },
    {
      fakultet: 'Qurilish',
      kafedralar: [
        'Bino va inshootlar qurilishi',
        'Muhandislik kommunikatsiyalari qurilishi va montaji',
        'Geodeziya, kartografiya va kadastr',
        'Qurilish materiallari, buyumlari va konstruksiyalarini ishlab chiqarish',
        'Arxitektura',
      ],
    },
    {
      fakultet: 'Ishlab chiqarishda boshqaruv',
      kafedralar: [
        'Iqtisodiyot',
        'Menejment',
        "Tillarni o'rgatish",
        'Buxgalteriya xisobi va audit',
        'O‘zbek tili va tillarni o‘rgatish',
      ],
    },
    {
      fakultet: "Yengil sanoat va to'qimachilik",
      kafedralar: [
        'Yengil sanoat buyumlari texnologiyasi',
        'Qishloq xo‘jalik mahsulotlarini saqlash va dastlabki ishlash texnologiyasi',
        'Tabiiy tolalar',
      ],
    },
    {
      fakultet: 'Kompyuterlashgan loyihalash tizimlari',
      kafedralar: [
        'Elektronika va asbobsozlik',
        'Intellektual muhandislik tizimlari',
        'Metrologiya standartlashtirish va maxsulot sifati menejmenti',
        'Ijtimoiy fanlar va sport',
      ],
    },
  ],
  grastud: 556,
  fakultet: [
    'Mexanika - mashinasozlik',
    'Energetika',
    'Kimyo texnologiya',
    'Qurilish',
    'Ishlab chiqarishda boshqaruv',
    "Yengil sanoat va to'qimachilik",
    'Kompyuterlashgan loyihalash tizimlari',
  ],
  kafedra: [
    'Mashinasozlik texnologiyasi, jihozlari va mashinasozlikda ishlab chiqarishni avtomatlashtirish',
    'Oliy matematika',
    'Tadbiqiy mexanika',
    'Texnologik mashinalar va jihozlar',
    'Yer usti transport tizimlari va ularni ekspluatatsiyasi',
    'Elektr energetika',
    'Elektr texnikasi, elektr mexanikasi va elektr texnologiyalari',
    'Fizika',
    'Chizma geometriya va muhandislik grafikasi',
    'Kimyo va kimyoviy texnologiya',
    'Neft va neft-gazni qayta ishlash texnologiyasi',
    'Oziq-ovqatlar texnologiyasi',
    'Bino va inshootlar qurilishi',
    'Muhandislik kommunikatsiyalari qurilishi va montaji',
    'Geodeziya, kartografiya va kadastr',
    'Qurilish materiallari, buyumlari va konstruksiyalarini ishlab chiqarish',
    'Arxitektura',
    'Iqtisodiyot',
    'Menejment',
    "Tillarni o'rgatish",
    'Buxgalteriya xisobi va audit',
    'O‘zbek tili va tillarni o‘rgatish',
    'Yengil sanoat buyumlari texnologiyasi',
    'Qishloq xo‘jalik mahsulotlarini saqlash va dastlabki ishlash texnologiyasi',
    'Tabiiy tolalar',
    'Elektronika va asbobsozlik',
    'Intellektual muhandislik tizimlari',
    'Metrologiya standartlashtirish va maxsulot sifati menejmenti',
    'Ijtimoiy fanlar va sport',
  ],
};
//////////////////
//Farg`ona davlat universiteti ta\'lim sifatini nazorat qilish bo\'limi
export const environment_fdu = {
  //Eslatma TSNQB so‘zi o‘rniga KPI
  otm: 'fdu',
  main_name: 'KPI',
  production: true,
  firebase: {
    email: 'fardu0tsnqb@gmail.com',
    p: 'g2h0^RE0k3Ax',
  },
  a: {
    email: 'O_A@',
    p: 'H%xieAh66N^2',
  },
  s: {
    l: 'kpifduuz',
    p: '!7Fz8OPgD7)a4k ser3',
  },
  http_auth: 'fghdUEfdko3FJIJsdfPK',
  otm_name:
    "Farg`ona davlat universiteti ta'lim sifatini nazorat qilish bo'limi",
  firebaseConfig: {
    // fdu
    apiKey: 'AIzaSyCtFGfn_M96D3hzQlxxUmMGTKHcNsp6oR4',
    authDomain: 'fdu1-138e9.firebaseapp.com',
    projectId: 'fdu1-138e9',
    storageBucket: 'fdu1-138e9.appspot.com',
    messagingSenderId: '86663595785',
    appId: '1:86663595785:web:fd512e84a8ce8cf86375d8',
    measurementId: 'G-B6GKYS7M9J',
  },
  http: {
    socket: {
      path: 'wss://kpi.fdu.uz/socket.io',
    },
    editArea: {
      path: 'https://kpi.fdu.uz/api/system/area',
    },
    addArea: {
      path: 'https://kpi.fdu.uz/api/system/area',
    },
    deleteArea: {
      path: 'https://kpi.fdu.uz/api/system/area',
    },
    removeDepartment: {
      path: 'https://kpi.fdu.uz/api/system/department',
    },
    editDepartment: {
      path: 'https://kpi.fdu.uz/api/system/department',
    },
    getDepartmentAArea: {
      path: 'https://kpi.fdu.uz/api/system/getAll',
    },
    addDepartment: {
      path: 'https://kpi.fdu.uz/api/system/department',
    },
    userRegister: {
      path: 'https://kpi.fdu.uz/api/auth/registration',
    },
    recoverPass: {
      path: 'https://kpi.fdu.uz/api/mailing/send-mail',
    },
    allRoles: {
      path: 'https://kpi.fdu.uz/api/auth/login',
    },
    removeRole: {
      path: 'https://kpi.fdu.uz/api/auth/login',
    },
    setRole: {
      path: 'https://kpi.fdu.uz/api/auth/login',
    },
    removeTeacher: {
      path: 'https://kpi.fdu.uz/api/auth/login',
    },
    allUsers: {
      path: 'https://kpi.fdu.uz/api/users/all',
    },
    updateUser: {
      path: 'https://kpi.fdu.uz/api/auth/login',
    },
    deleteUser: {
      path: 'https://kpi.fdu.uz/api/auth/login',
    },
    login: {
      path: 'https://kpi.fdu.uz/api/auth/login',
    },
    autologin: {
      path: 'https://kpi.fdu.uz/api/auth/autologin',
    },
    get: {
      // https://tsnqb.ferpi.uz/db_pvorey_test //https://2shahar.uz/tsnqb/get_data.php
      path: 'https://kpi.fdu.uz/db/2023/get_data.php',
      file: 'pvorey.txt',
    },
    delete_only_inform_file: {
      path: 'https://kpi.fdu.uz/db/2023/delete_only_inform_file.php',
    },
    getFieldsInform: {
      path: 'https://kpi.fdu.uz/db/2023/get_fields_inform.php',
    },
    setInc: {
      path: 'https://kpi.fdu.uz/db/2023/set_data_inc.php',
      file: 'pvorey.txt',
    },
    del: {
      path: 'https://kpi.fdu.uz/db/2023/del_data.php',
      file: 'pvorey.txt',
    },
    setIncDelUpd: {
      path: 'https://kpi.fdu.uz/db/2023/set_inc_del_upd.php',
      file: 'pvorey.txt',
    },
    remDocDownGrade: {
      path: 'https://kpi.fdu.uz/db/2023/rem_doc_with_down_grade.php',
    },
    googleScholarCited: {
      path: 'https://kpi.fdu.uz/db/2023/google_scholar.php',
    },
    googleScholarCitedAll: {
      path: 'https://kpi.fdu.uz/db_ferpi/2023/google_scholar_all.php',
    },
    setNewPvo: {
      path: 'https://kpi.fdu.uz/db/2023/set_new_pvo.php',
    },
    removePvo: {
      path: 'https://kpi.fdu.uz/db/2023/remove_pvo.php',
    },
    controlIndexStatus: {
      path: 'https://kpi.fdu.uz/db/2023/control_index_status.php',
    },
    createAnnounce: {
      path: 'https://kpi.fdu.uz/db/2023/create_announce.php',
    },
    getAnnounces: {
      path: 'https://kpi.fdu.uz/db/2023/get_announces.php',
    },
    removeAnnounce: {
      path: 'https://kpi.fdu.uz/db/2023/remove_announce.php',
    },
    editUserAccaunt: {
      path: 'https://kpi.fdu.uz/db/2023/edit_user_account.php',
    },
    createIndex: {
      path: 'https://kpi.fdu.uz/db/2023/create_index.php',
    },
  },
  tizim: [
    {
      fakultet: 'Fizika-texnika',
      kafedralar: ['Fizika', 'Texnologik taʼlim'],
    },
    {
      fakultet: 'Matematika-informatika',
      kafedralar: [
        'Matematika',
        'Matematik analiz va differentsial tenglamalar',
        'Аmaliy matematika va informatika',
        'Аxborot texnologiyalari',
      ],
    },
    {
      fakultet: 'Tabiiy fanlar',
      kafedralar: [
        'Kimyo',
        'Geografiya',
        'Ekologiya',
        'Zoologiya va umumiy biologiya',
        'Botanika va biotexnologiya',
      ],
    },
    {
      fakultet: 'Agrar qo‘shma',
      kafedralar: [
        'Аholi tomorqalaridan samarali foydalanish va dorivor o‘simliklar',
        'Tuproqshunoslik',
        'Mevachilik va sabzavotchilik',
      ],
    },
    {
      fakultet: 'Chet tillari',
      kafedralar: [
        'Ingliz tili',
        'Nemis va frantsuz tillari',
        'Gumanitar yoʼnalishlar boʼyicha chet tillari',
        'Tabiiy yoʼnalishlar boʼyicha chet tillari',
      ],
    },
    {
      fakultet: 'Ingliz tili va adabiyoti',
      kafedralar: ['Аmaliy ingliz tili', 'Ingliz tili oʼqitish metodikasi'],
    },
    {
      fakultet: 'Filologiya',
      kafedralar: [
        'Tilshunoslik',
        'Аdabiyotshunoslik',
        'Oʼzbek tili va adabiyoti',
        'Rus filologiyasi',
        'Rus tili metodikasi',
      ],
    },
    {
      fakultet: 'Tarix',
      kafedralar: [
        'Jahon tarixi',
        'Oʼzbekiston tarixi',
        'Sotsiologiya',
        'Falsafa',
        'Ijtimoiy ish',
        'Huquq ta’limi',
      ],
    },
    {
      fakultet: 'Pedagogika-psixologiya',
      kafedralar: ['Pedagogika', 'Psixologiya'],
    },
    {
      fakultet: 'Sanʼatshunoslik',
      kafedralar: [
        'Tasviriy sanʼat',
        'Musiqiy taʼlim va madaniyat',
        'Vokal va cholgʼu ijrochiligi',
      ],
    },
    {
      fakultet: 'Maktabgacha va boshlangʼich taʼlim',
      kafedralar: ['Boshlangʼich taʼlim uslubiyoti', 'Maktabgacha taʼlim'],
    },
    {
      fakultet: 'Jismoniy madaniyat',
      kafedralar: [
        'Jismoniy madaniyat',
        'Jismoniy madaniyat nazariyasi va uslubiyoti',
        'Sport oʼyinlari',
      ],
    },
    {
      fakultet: 'Iqtisodiyot',
      kafedralar: [
        'Jahon va mintaqa iqtisodiyoti',
        'Iqtisodiyot va servis',
        'Moliya',
        'Buxgalteriya hisobi va iqtisodiy taxlil',
      ],
    },
    {
      fakultet: 'Harbiy taʼlim',
      kafedralar: ['Harbiy taʼlim'],
    },
    {
      fakultet: 'Sirtqi boʼlim',
      kafedralar: ['Аniq va tabiiy fanlar', 'Ijtimoiy gumanitar fanlar'],
    },
  ],
  grastud: 556,
  fakultet: [
    'Fizika-texnika',
    'Matematika-informatika',
    'Tabiiy fanlar',
    'Agrar qo‘shma',
    'Chet tillari',
    'Ingliz tili va adabiyoti',
    'Filologiya',
    'Tarix',
    'Pedagogika-psixologiya',
    'Sanʼatshunoslik',
    'Maktabgacha va boshlangʼich taʼlim',
    'Jismoniy madaniyat',
    'Iqtisodiyot',
    'Harbiy taʼlim',
    'Sirtqi boʼlim',
  ],
  kafedra: [
    'Fizika',
    'Texnologik taʼlim',
    'Matematika',
    'Matematik analiz va differentsial tenglamalar',
    'Аmaliy matematika va informatika',
    'Аxborot texnologiyalari',
    'Kimyo',
    'Geografiya',
    'Ekologiya',
    'Zoologiya va umumiy biologiya',
    'Botanika va biotexnologiya',
    'Аholi tomorqalaridan samarali foydalanish va dorivor o‘simliklar',
    'Tuproqshunoslik',
    'Mevachilik va sabzavotchilik',
    'Ingliz tili',
    'Nemis va frantsuz tillari',
    'Gumanitar yoʼnalishlar boʼyicha chet tillari',
    'Tabiiy yoʼnalishlar boʼyicha chet tillari',
    'Аmaliy ingliz tili',
    'Ingliz tili oʼqitish metodikasi',
    'Tilshunoslik',
    'Аdabiyotshunoslik',
    'Oʼzbek tili va adabiyoti',
    'Rus filologiyasi',
    'Rus tili metodikasi',
    'Jahon tarixi',
    'Oʼzbekiston tarixi',
    'Sotsiologiya',
    'Falsafa',
    'Ijtimoiy ish',
    'Huquq ta’limi',
    'Pedagogika',
    'Psixologiya',
    'Tasviriy sanʼat',
    'Musiqiy taʼlim va madaniyat',
    'Vokal va cholgʼu ijrochiligi',
    'Boshlangʼich taʼlim uslubiyoti',
    'Maktabgacha taʼlim',
    'Jismoniy madaniyat',
    'Jismoniy madaniyat nazariyasi va uslubiyoti',
    'Sport oʼyinlari',
    'Jahon va mintaqa iqtisodiyoti',
    'Iqtisodiyot va servis',
    'Moliya',
    'Buxgalteriya hisobi va iqtisodiy taxlil',
    'Harbiy taʼlim',
    'Аniq va tabiiy fanlar',
    'Ijtimoiy gumanitar fanlar',
  ],
};
//////////////////
//tatuff Fargona Tatu
export const environment_tatuff = {
  otm: 'tatuff',
  //website tsnqb.fbtuit.uz
  firebase: {
    email: 'tatuff0tsnqb@gmail.com',
    p: '7^^VnW5&9vY97!xa',
  },
  production: true,
  http_auth: 'IGIGDhvM9bmt5Wp6',
  otm_name: "TATU Farg‘ona filiali ta'lim sifatini nazorat qilish bo'limi",
  firebaseConfig: {
    // pvorey
    apiKey: 'AIzaSyAqG-vEaVdIofWMy2FbglnhKltHxz2_-kc',
    authDomain: 'tatuff-3b746.firebaseapp.com',
    projectId: 'tatuff-3b746',
    storageBucket: 'tatuff-3b746.appspot.com',
    messagingSenderId: '916599363478',
    appId: '1:916599363478:web:ca0c36128fa33e82d36fb7',
    measurementId: 'G-3PTZF2EX2K',
  },
  http: {
    socket: {
      path: 'ws://ferpi.tsnqb.uz/socket.io',
    },
    editArea: {
      path: 'https://tsnqb.fbtuit.uz/api/system/area',
    },
    addArea: {
      path: 'https://tsnqb.fbtuit.uz/api/system/area',
    },
    deleteArea: {
      path: 'https://tsnqb.fbtuit.uz/api/system/area',
    },
    removeDepartment: {
      path: 'https://tsnqb.fbtuit.uz/api/system/department',
    },
    editDepartment: {
      path: 'https://tsnqb.fbtuit.uz/api/system/department',
    },
    getDepartmentAArea: {
      path: 'https://tsnqb.fbtuit.uz/api/system/getAll',
    },
    addDepartment: {
      path: 'https://tsnqb.fbtuit.uz/api/system/department',
    },
    userRegister: {
      path: 'https://tsnqb.fbtuit.uz/api/auth/registration',
    },
    recoverPass: {
      path: 'https://tsnqb.fbtuit.uz/api/mailing/send-mail',
    },
    allRoles: {
      path: 'https://tsnqb.fbtuit.uz/api/auth/login',
    },
    removeRole: {
      path: 'https://tsnqb.fbtuit.uz/api/auth/login',
    },
    setRole: {
      path: 'https://tsnqb.fbtuit.uz/api/auth/login',
    },
    removeTeacher: {
      path: 'https://tsnqb.fbtuit.uz/api/auth/login',
    },
    allUsers: {
      path: 'https://tsnqb.fbtuit.uz/api/users/all',
    },
    updateUser: {
      path: 'https://tsnqb.fbtuit.uz/api/auth/login',
    },
    deleteUser: {
      path: 'https://tsnqb.fbtuit.uz/api/auth/login',
    },
    login: {
      path: 'https://tsnqb.fbtuit.uz/api/auth/login',
    },
    autologin: {
      path: 'https://tsnqb.fbtuit.uz/api/auth/autologin',
    },
    get: {
      //https://tsnqb.ferpi.uz/db_pvorey_test //https://2shahar.uz/tsnqb/get_data.php
      path: 'https://tsnqb.fbtuit.uz/db/2023/get_data.php',
      file: 'pvorey.txt',
    },
    getFieldsInform: {
      path: 'https://tsnqb.fbtuit.uz/db/2023/get_fields_inform.php',
    },
    delete_only_inform_file: {
      path: 'https://tsnqb.fbtuit.uz/db/2023/delete_only_inform_file.php',
    },
    setInc: {
      path: 'https://tsnqb.fbtuit.uz/db/2023/set_data_inc.php',
      file: 'pvorey.txt',
    },
    del: {
      path: 'https://tsnqb.fbtuit.uz/db/2023/del_data.php',
      file: 'pvorey.txt',
    },
    setIncDelUpd: {
      path: 'https://tsnqb.fbtuit.uz/db/2023/set_inc_del_upd.php',
      file: 'pvorey.txt',
    },
    remDocDownGrade: {
      path: 'https://tsnqb.fbtuit.uz/db/2023/rem_doc_with_down_grade.php',
    },
    googleScholarCited: {
      path: 'https://tsnqb.fbtuit.uz/db/2023/google_scholar.php',
    },
    googleScholarCitedAll: {
      path: 'https://tsnqb.fbtuit.uz/db/2023/google_scholar_all.php',
    },
    setNewPvo: {
      path: 'https://tsnqb.fbtuit.uz/db/2023/set_new_pvo.php',
    },
    removePvo: {
      path: 'https://tsnqb.fbtuit.uz/db/2023/remove_pvo.php',
    },
    controlIndexStatus: {
      path: 'https://tsnqb.fbtuit.uz/db/2023/control_index_status.php',
    },
    createAnnounce: {
      path: 'https://tsnqb.fbtuit.uz/db/2023/create_announce.php',
    },
    getAnnounces: {
      path: 'https://tsnqb.fbtuit.uz/db/2023/get_announces.php',
    },
    removeAnnounce: {
      path: 'https://tsnqb.fbtuit.uz/db/2023/remove_announce.php',
    },
    editUserAccaunt: {
      path: 'https://tsnqb.fbtuit.uz/db/2023/edit_user_account.php',
    },
    createIndex: {
      path: 'https://tsnqb.fbtuit.uz/db/2023/create_index.php',
    },
  },
  tizim: [
    {
      fakultet: 'Kompyuter injiniring',
      kafedralar: [
        'Axborot texnologiyalari',
        'Kompyuter tizimlari',
        'Tabiiy fanlar',
      ],
    },
    {
      fakultet: 'Dasturiy injiniringi va raqamli iqtisodiyot',
      kafedralar: [
        'Axborot xavfsizligi',
        'Dasturiy injiniring',
        "O'zbek tili va gumanitar fanlar",
      ],
    },
    {
      fakultet: "Telekomunikatsiya injiniringi va kasb ta'limi",
      kafedralar: [
        'Telekomunikatsiya injiniringi',
        'Axborot ta’lim texnologiyalari',
        'Xorijiy tillar',
      ],
    },
  ],
  grastud: 556,
  fakultet: [
    'Kompyuter injiniring',
    'Dasturiy injiniringi va raqamli iqtisodiyot',
    "Telekomunikatsiya injiniringi va kasb ta'limi",
  ],
  kafedra: [
    'Axborot texnologiyalari',
    'Kompyuter tizimlari',
    'Tabiiy fanlar',
    'Axborot xavfsizligi',
    'Dasturiy injiniring',
    "O'zbek tili va gumanitar fanlar",
    'Telekomunikatsiya injiniringi',
    'Axborot ta’lim texnologiyalari',
    'Xorijiy tillar',
  ],
};
export const environment_tdtukokand = {
  otm: 'tdtukokand',
  //website tdtukokand.tsnqb.uz
  firebase: {
    email: 'tdtukokand0tsnqb@gmail.com',
    p: 'j5LFa%k69#6!T3',
  },
  a: {
    email: 'O_A@',
    p: 'b!',
  },
  production: true,
  http_auth: 'EIFMNEMWFE93FNf',
  otm_name: "TDTU Qo‘qon filiali ta'lim sifatini nazorat qilish bo'limi",
  firebaseConfig: {
    apiKey: 'AIzaSyC1ktqlK-zy1s1uf9KmXA3K6WJg-byN6fo',
    authDomain: 'tdtukokand-9264d.firebaseapp.com',
    projectId: 'tdtukokand-9264d',
    storageBucket: 'tdtukokand-9264d.appspot.com',
    messagingSenderId: '1060781214963',
    appId: '1:1060781214963:web:dbca7ee122f102d5d37b86',
    measurementId: 'G-RN64626WH5',
  },
  http: {
    socket: {
      path: 'wss://ferpi.tsnqb.uz/socket.io',
    },
    editDepartment: {
      path: 'https://tdtukokand.tsnqb.uz/api/system/department',
    },
    getDepartmentAArea: {
      path: 'https://tdtukokand.tsnqb.uz/api/system/getAll',
    },
    addDepartment: {
      path: 'https://tdtukokand.tsnqb.uz/api/system/department',
    },
    editArea: {
      path: 'https://tdtukokand.tsnqb.uz/api/system/area',
    },
    addArea: {
      path: 'https://tdtukokand.tsnqb.uz/api/system/area',
    },
    deleteArea: {
      path: 'https://tdtukokand.tsnqb.uz/api/system/area',
    },
    removeDepartment: {
      path: 'https://tdtukokand.tsnqb.uz/api/system/department',
    },
    userRegister: {
      path: 'https://tdtukokand.tsnqb.uz/api/auth/registration',
    },
    recoverPass: {
      path: 'https://tdtukokand.tsnqb.uz/api/mailing/send-mail',
    },
    allRoles: {
      path: 'https://tdtukokand.tsnqb.uz/api/auth/login',
    },
    removeRole: {
      path: 'https://tdtukokand.tsnqb.uz/api/auth/login',
    },
    setRole: {
      path: 'https://tdtukokand.tsnqb.uz/api/auth/login',
    },
    removeTeacher: {
      path: 'https://tdtukokand.tsnqb.uz/api/auth/login',
    },
    allUsers: {
      path: 'https://tdtukokand.tsnqb.uz/api/users/all',
    },
    updateUser: {
      path: 'https://tdtukokand.tsnqb.uz/api/auth/login',
    },
    deleteUser: {
      path: 'https://tdtukokand.tsnqb.uz/api/auth/login',
    },
    login: {
      path: 'https://tdtukokand.tsnqb.uz/api/auth/login',
    },
    autologin: {
      path: 'https://tdtukokand.tsnqb.uz/api/auth/autologin',
    },
    get: {
      path: 'https://tdtukokand.tsnqb.uz/db/2023/get_data.php',
      file: 'pvorey.txt',
    },
    getFieldsInform: {
      path: 'https://tdtukokand.tsnqb.uz/db/2023/get_fields_inform.php',
    },
    delete_only_inform_file: {
      path: 'https://tdtukokand.tsnqb.uz/db/2023/delete_only_inform_file.php',
    },
    setInc: {
      path: 'https://tdtukokand.tsnqb.uz/db/2023/set_data_inc.php',
      file: 'pvorey.txt',
    },
    del: {
      path: 'https://tdtukokand.tsnqb.uz/db/2023/del_data.php',
      file: 'pvorey.txt',
    },
    setIncDelUpd: {
      path: 'https://tdtukokand.tsnqb.uz/db/2023/set_inc_del_upd.php',
      file: 'pvorey.txt',
    },
    remDocDownGrade: {
      path: 'https://tdtukokand.tsnqb.uz/db/2023/rem_doc_with_down_grade.php',
    },
    googleScholarCited: {
      path: 'https://tdtukokand.tsnqb.uz/db/2023/google_scholar.php',
    },
    googleScholarCitedAll: {
      path: 'https://tdtukokand.tsnqb.uz/db/2023/google_scholar_all.php',
    },
    setNewPvo: {
      path: 'https://tdtukokand.tsnqb.uz/db/2023/set_new_pvo.php',
    },
    removePvo: {
      path: 'https://tdtukokand.tsnqb.uz/db/2023/remove_pvo.php',
    },
    controlIndexStatus: {
      path: 'https://tdtukokand.tsnqb.uz/db/2023/control_index_status.php',
    },
    createAnnounce: {
      path: 'https://tdtukokand.tsnqb.uz/db/2023/create_announce.php',
    },
    getAnnounces: {
      path: 'https://tdtukokand.tsnqb.uz/db/2023/get_announces.php',
    },
    removeAnnounce: {
      path: 'https://tdtukokand.tsnqb.uz/db/2023/remove_announce.php',
    },
    editUserAccaunt: {
      path: 'https://tdtukokand.tsnqb.uz/db/2023/edit_user_account.php',
    },
    createIndex: {
      path: 'https://tdtukokand.tsnqb.uz/db/2023/create_index.php',
    },
  },
  tizim: [
    {
      fakultet: 'Energetika',
      kafedralar: ['Elektr texnologiyalari', 'Ijtimoiy va gumanitar fanlar'],
    },
    {
      fakultet: 'Muhandislik',
      kafedralar: [
        'Muhandislik texnologiyalari',
        'Kimyoviy texnologiyalari',
        'Materialshunoslik va aniq fanlar',
      ],
    },
  ],
  grastud: 556,
  fakultet: ['Energetika', 'Muhandislik'],
  kafedra: [
    'Elektr texnologiyalari',
    'Ijtimoiy va gumanitar fanlar',
    'Muhandislik texnologiyalari',
    'Kimyoviy texnologiyalari',
    'Materialshunoslik va aniq fanlar',
  ],
};
export const environment_uzdjtsuff = {
  //Eslatma TSNQB
  otm: 'uzdjtsuff',
  main_name: 'TSNQB',
  production: true,
  firebase: {
    email: 'uzdjt0tsnqb@gmail.com',
    p: 'sdfgojs@jmgi',
  },
  a: {
    email: 'O_A@',
    p: 'H%xieAh66N^2',
  },
  http_auth: 'asnfa@#fasdffsedfnnsd',
  otm_name: "Ta'lim sifatini nazorat qilish bo'limi",
  firebaseConfig: {
    // fdu
    apiKey: 'AIzaSyBs2hR0qibVtXI6NHKsD7tTZo3leTTzrVo',
    authDomain: 'pvorey-8f8a3.firebaseapp.com',
    projectId: 'pvorey-8f8a3',
    storageBucket: 'pvorey-8f8a3.appspot.com',
    messagingSenderId: '110763375175',
    appId: '1:110763375175:web:b0ec673a4b3c38e5bc0f62',
    measurementId: 'G-3EBYBCYC70',
  },
  http: {
    userRegister: {
      path: 'https://uzdjtsuff.tsnqb.uz/api/auth/registration',
    },
    recoverPass: {
      path: 'https://uzdjtsuff.tsnqb.uz/api/mailing/send-mail',
    },
    allRoles: {
      path: 'https://uzdjtsuff.tsnqb.uz/api/auth/login',
    },
    removeRole: {
      path: 'https://uzdjtsuff.tsnqb.uz/api/auth/login',
    },
    setRole: {
      path: 'https://uzdjtsuff.tsnqb.uz/api/auth/login',
    },
    removeTeacher: {
      path: 'https://uzdjtsuff.tsnqb.uz/api/auth/login',
    },
    allUsers: {
      path: 'https://uzdjtsuff.tsnqb.uz/api/users/all',
    },
    updateUser: {
      path: 'https://uzdjtsuff.tsnqb.uz/api/auth/login',
    },
    deleteUser: {
      path: 'https://uzdjtsuff.tsnqb.uz/api/auth/login',
    },
    login: {
      path: 'https://uzdjtsuff.tsnqb.uz/api/auth/login',
    },
    autologin: {
      path: 'https://uzdjtsuff.tsnqb.uz/api/auth/autologin',
    },
    get: {
      // https://tsnqb.ferpi.uz/db_pvorey_test //https://2shahar.uz/tsnqb/get_data.php
      path: 'https://uzdjtsuff.tsnqb.uz/db/2023/get_data.php',
      file: 'pvorey.txt',
    },
    delete_only_inform_file: {
      path: 'https://uzdjtsuff.tsnqb.uz/db/2023/delete_only_inform_file.php',
    },
    getFieldsInform: {
      path: 'https://uzdjtsuff.tsnqb.uz/db/2023/get_fields_inform.php',
    },
    setInc: {
      path: 'https://uzdjtsuff.tsnqb.uz/db/2023/set_data_inc.php',
      file: 'pvorey.txt',
    },
    del: {
      path: 'https://uzdjtsuff.tsnqb.uz/db/2023/del_data.php',
      file: 'pvorey.txt',
    },
    setIncDelUpd: {
      path: 'https://uzdjtsuff.tsnqb.uz/db/2023/set_inc_del_upd.php',
      file: 'pvorey.txt',
    },
    remDocDownGrade: {
      path: 'https://uzdjtsuff.tsnqb.uz/db/2023/rem_doc_with_down_grade.php',
    },
    googleScholarCited: {
      path: 'https://uzdjtsuff.tsnqb.uz/db/2023/google_scholar.php',
    },
    googleScholarCitedAll: {
      path: 'https://uzdjtsuff.tsnqb.uz/db_ferpi/2023/google_scholar_all.php',
    },
    setNewPvo: {
      path: 'https://uzdjtsuff.tsnqb.uz/db/2023/set_new_pvo.php',
    },
    removePvo: {
      path: 'https://uzdjtsuff.tsnqb.uz/db/2023/remove_pvo.php',
    },
    controlIndexStatus: {
      path: 'https://uzdjtsuff.tsnqb.uz/db/2023/control_index_status.php',
    },
    createAnnounce: {
      path: 'https://uzdjtsuff.tsnqb.uz/db/2023/create_announce.php',
    },
    getAnnounces: {
      path: 'https://uzdjtsuff.tsnqb.uz/db/2023/get_announces.php',
    },
    removeAnnounce: {
      path: 'https://uzdjtsuff.tsnqb.uz/db/2023/remove_announce.php',
    },
    editUserAccaunt: {
      path: 'https://uzdjtsuff.tsnqb.uz/db/2023/edit_user_account.php',
    },
    createIndex: {
      path: 'https://uzdjtsuff.tsnqb.uz/db/2023/create_index.php',
    },
  },
  tizim: [
    {
      fakultet: 'Sport turlari',
      kafedralar: [
        'Yakkakurash sport turlari nazaryasi va uslubyati',
        'Sport o‘yinlari nazariyasi va uslubyati',
        'Ko‘pkurash sport turlari nazariyasi va uslubyati',
        'Jismoniy tarbiya, sport nazariyasi va uslubiyati',
      ],
    },
  ],
  grastud: 300,
  fakultet: ['Sport turlari'],
  kafedra: [
    'Yakkakurash sport turlari nazaryasi va uslubyati',
    'Sport o‘yinlari nazariyasi va uslubyati',
    'Ko‘pkurash sport turlari nazariyasi va uslubyati',
    'Jismoniy tarbiya, sport nazariyasi va uslubiyati',
  ],
};
export const environment_demo = {
  otm: 'demo',
  //website tsnqb.fbtuit.uz
  firebase: {
    email: 'b07889249@gmail.com',
    p: 'bekgzod94',
  },
  a: {
    email: 'O_A@',
    p: '123456',
  },
  production: true,
  http_auth: 'IGIGDhvM9bmt5Wp6',
  otm_name: "Ta'lim sifatini nazorat qilish bo'limi",
  firebaseConfig: {
    apiKey: 'AIzaSyDiAjbnVOPQw62A0IO08TA6l1UX8TS3RAE',
    authDomain: 'fir-d57d3.firebaseapp.com',
    projectId: 'fir-d57d3',
    storageBucket: 'fir-d57d3.appspot.com',
    messagingSenderId: '714622570281',
    appId: '1:714622570281:web:a278803f6258e11205b138',
    measurementId: 'G-176KSVQ15G',
  },
  http: {
    socket: {
      path: 'wss://tsnq.uz/socket.io',
    },
    userRegister: {
      path: 'https://tsnq.uz/api/auth/registration',
    },
    recoverPass: {
      path: 'https://tsnq.uz/api/mailing/send-mail',
    },
    allRoles: {
      path: 'https://tsnq.uz/api/auth/login',
    },
    removeRole: {
      path: 'https://tsnq.uz/api/auth/login',
    },
    setRole: {
      path: 'https://tsnq.uz/api/auth/login',
    },
    removeTeacher: {
      path: 'https://tsnq.uz/api/auth/login',
    },
    allUsers: {
      path: 'https://tsnq.uz/api/users/all',
    },
    updateUser: {
      path: 'https://tsnq.uz/api/auth/login',
    },
    deleteUser: {
      path: 'https://tsnq.uz/api/auth/login',
    },
    login: {
      path: 'https://tsnq.uz/api/auth/login',
    },
    autologin: {
      path: 'https://tsnq.uz/api/auth/autologin',
    },
    get: {
      path: 'https://tsnq.uz/db/2023/get_data.php',
      file: 'pvorey.txt',
    },
    getFieldsInform: {
      path: 'https://tsnq.uz/db/2023/get_fields_inform.php',
    },
    delete_only_inform_file: {
      path: 'https://tsnq.uz/db/2023/delete_only_inform_file.php',
    },
    setInc: {
      path: 'https://tsnq.uz/db/2023/set_data_inc.php',
      file: 'pvorey.txt',
    },
    del: {
      path: 'https://tsnq.uz/db/2023/del_data.php',
      file: 'pvorey.txt',
    },
    setIncDelUpd: {
      path: 'https://tsnq.uz/db/2023/set_inc_del_upd.php',
      file: 'pvorey.txt',
    },
    remDocDownGrade: {
      path: 'https://tsnq.uz/db/2023/rem_doc_with_down_grade.php',
    },
    googleScholarCited: {
      path: 'https://tsnq.uz/db/2023/google_scholar.php',
    },
    googleScholarCitedAll: {
      path: 'https://tsnq.uz/db/2023/google_scholar_all.php',
    },
    setNewPvo: {
      path: 'https://tsnq.uz/db/2023/set_new_pvo.php',
    },
    removePvo: {
      path: 'https://tsnq.uz/db/2023/remove_pvo.php',
    },
    controlIndexStatus: {
      path: 'https://tsnq.uz/db/2023/control_index_status.php',
    },
    createAnnounce: {
      path: 'https://tsnq.uz/db/2023/create_announce.php',
    },
    getAnnounces: {
      path: 'https://tsnq.uz/db/2023/get_announces.php',
    },
    removeAnnounce: {
      path: 'https://tsnq.uz/db/2023/remove_announce.php',
    },
    editUserAccaunt: {
      path: 'https://tsnq.uz/db/2023/edit_user_account.php',
    },
    createIndex: {
      path: 'https://tsnq.uz/db/2023/create_index.php',
    },
  },
  tizim: [
    {
      fakultet: 'Kompyuter injiniring',
      kafedralar: [
        'Axborot texnologiyalari',
        'Kompyuter tizimlari',
        'Tabiiy fanlar',
      ],
    },
    {
      fakultet: 'Dasturiy injiniringi va raqamli iqtisodiyot',
      kafedralar: [
        'Axborot xavfsizligi',
        'Dasturiy injiniring',
        "O'zbek tili va gumanitar fanlar",
      ],
    },
    {
      fakultet: "Telekomunikatsiya injiniringi va kasb ta'limi",
      kafedralar: [
        'Telekomunikatsiya injiniringi',
        'Axborot ta’lim texnologiyalari',
        'Xorijiy tillar',
      ],
    },
  ],
  grastud: 556,
  fakultet: [
    'Kompyuter injiniring',
    'Dasturiy injiniringi va raqamli iqtisodiyot',
    "Telekomunikatsiya injiniringi va kasb ta'limi",
  ],
  kafedra: [
    'Axborot texnologiyalari',
    'Kompyuter tizimlari',
    'Tabiiy fanlar',
    'Axborot xavfsizligi',
    'Dasturiy injiniring',
    "O'zbek tili va gumanitar fanlar",
    'Telekomunikatsiya injiniringi',
    'Axborot ta’lim texnologiyalari',
    'Xorijiy tillar',
  ],
};
//fjsti prod///////////
export const environment_fjsti = {
  otm: 'fjsti',
  firebase: {
    email: 'fjsti0tsnqb@gmail.com',
    p: 'asdf@rfgf34$',
  },
  a: {
    email: 'O_A@',
    p: 'osha',
  },
  production: true,
  http_auth: 'NSJNAB&237BNSFISNM',
  otm_name:
    "Farg'ona jamoat salomatligi tibbiyot insituti ta'lim sifatini nazorat qilish bo'limi",
  firebaseConfig: {
    // pvorey
    apiKey: 'AIzaSyCdXdbQrCOcdXI183VIcmetut_dCKG5dOU',
    authDomain: 'fjsti-c50ff.firebaseapp.com',
    projectId: 'fjsti-c50ff',
    storageBucket: 'fjsti-c50ff.appspot.com',
    messagingSenderId: '382705767044',
    appId: '1:382705767044:web:48527a1aeb7e8674c4e8d1',
    measurementId: 'G-L4NGYJKBMN',
  },
  http: {
    socket: {
      path: 'ws://ferpi.tsnqb.uz/socket.io',
    },
    editArea: {
      path: 'https://tsnqb.fjsti.uz/api/system/area',
    },
    addArea: {
      path: 'https://tsnqb.fjsti.uz/api/system/area',
    },
    deleteArea: {
      path: 'https://tsnqb.fjsti.uz/api/system/area',
    },
    removeDepartment: {
      path: 'https://tsnqb.fjsti.uz/api/system/department',
    },
    editDepartment: {
      path: 'https://tsnqb.fjsti.uz/api/system/department',
    },
    getDepartmentAArea: {
      path: 'https://tsnqb.fjsti.uz/api/system/getAll',
    },
    addDepartment: {
      path: 'https://tsnqb.fjsti.uz/api/system/department',
    },
    userRegister: {
      path: 'https://tsnqb.fjsti.uz/api/auth/registration',
    },
    recoverPass: {
      path: 'https://tsnqb.fjsti.uz/api/mailing/send-mail',
    },
    allRoles: {
      path: 'https://tsnqb.fjsti.uz/api/auth/login',
    },
    removeRole: {
      path: 'https://tsnqb.fjsti.uz/api/auth/login',
    },
    setRole: {
      path: 'https://tsnqb.fjsti.uz/api/auth/login',
    },
    removeTeacher: {
      path: 'https://tsnqb.fjsti.uz/api/auth/login',
    },
    allUsers: {
      path: 'https://tsnqb.fjsti.uz/api/users/all',
    },
    updateUser: {
      path: 'https://tsnqb.fjsti.uz/api/auth/login',
    },
    deleteUser: {
      path: 'https://tsnqb.fjsti.uz/api/auth/login',
    },
    login: {
      path: 'https://tsnqb.fjsti.uz/api/auth/login',
    },
    autologin: {
      path: 'https://tsnqb.fjsti.uz/api/auth/autologin',
    },
    get: {
      path: 'https://tsnqb.fjsti.uz/db/2024/get_data.php',
      file: 'pvorey.txt',
    },
    delete_only_inform_file: {
      path: 'https://tsnqb.fjsti.uz/db/2024/delete_only_inform_file.php',
    },
    getFieldsInform: {
      path: 'https://tsnqb.fjsti.uz/db/2024/get_fields_inform.php',
    },
    setInc: {
      path: 'https://tsnqb.fjsti.uz/db/2024/set_data_inc.php',
      file: 'pvorey.txt',
    },
    del: {
      path: 'https://tsnqb.fjsti.uz/db/2024/del_data.php',
      file: 'pvorey.txt',
    },
    setIncDelUpd: {
      path: 'https://tsnqb.fjsti.uz/db/2024/set_inc_del_upd.php',
      file: 'pvorey.txt',
    },
    remDocDownGrade: {
      path: 'https://tsnqb.fjsti.uz/db/2024/rem_doc_with_down_grade.php',
    },
    googleScholarCited: {
      path: 'https://tsnqb.fjsti.uz/db/2024/google_scholar.php',
    },
    googleScholarCitedAll: {
      path: 'https://tsnqb.fjsti.uz/db/2024/google_scholar_all.php',
    },
    setNewPvo: {
      path: 'https://tsnqb.fjsti.uz/db/2024/set_new_pvo.php',
    },
    removePvo: {
      path: 'https://tsnqb.fjsti.uz/db/2024/remove_pvo.php',
    },
    controlIndexStatus: {
      path: 'https://tsnqb.fjsti.uz/db/2024/control_index_status.php',
    },
    createAnnounce: {
      path: 'https://tsnqb.fjsti.uz/db/2024/create_announce.php',
    },
    getAnnounces: {
      path: 'https://tsnqb.fjsti.uz/db/2024/get_announces.php',
    },
    removeAnnounce: {
      path: 'https://tsnqb.fjsti.uz/db/2024/remove_announce.php',
    },
    editUserAccaunt: {
      path: 'https://tsnqb.fjsti.uz/db/2024/edit_user_account.php',
    },
    createIndex: {
      path: 'https://tsnqb.fjsti.uz/db/2024/create_index.php',
    },
  },
  tizim: [
    {
      fakultet: 'Tibbiy profilaktika va jamoat salomatligi',
      kafedralar: [
        "O'zbek va xorijiy tillar",
        'Lotin tili, pedagogika va psixologiya',
        'Kommunal va mehnat gigiyenasi',
        'Ovqatlanish, bolalar va o’smirlar gigienasi',
        'Preventiv tibbiyot asoslari, amoat salomatligi, jismoniy tarbiya va sport',
        'Epidemiologiya va yuqumli kasalliklar, hamshiralik ishi',
        'Mikrobiologiya, virusologiya va immunologiya',
        'Biotibbiyot muhandisligi',
      ],
    },
    {
      fakultet: 'Davolash',
      kafedralar: [
        'Ichki kasallalliklar propedevtikasi',
        'Terapiya yo’nalishidagi fanlar',
        'Patologik fiziologiya va patologik anatomiya',
        'Travmatologiya va ortopediya',
        'Fiziologiya',
        'Normal anatomiya, operativ jarrohlik va topografik anatomiya',
        'Gospital terapiya (ftiziatriya, laboratoriya)',
      ],
    },
    {
      fakultet: 'Pediatriya',
      kafedralar: [
        'Tibbiy va biologik kimyo',
        'Xalq tabobati va farmakologiya',
        'Ijtimoiy fanlar',
        'Urologiya va onkologiya',
        'Nevrologiya va psixiatriya',
        'Pediatriya',
        'Stomatologiya va otoloringologiya',
      ],
    },
    {
      fakultet: 'Xalqaro',
      kafedralar: [
        'Biofizika va axborot texnologiyalari',
        'Gistologiya va biologiya',
        'USMLE',
        'Akusherlik va ginekologiya',
        'Umumiy jarrohlik',
        'Fakultet va gospital jarrohlik',
      ],
    },
  ],
  grastud: 600,
  fakultet: [
    'Tibbiy profilaktika va jamoat salomatligi',
    'Davolash',
    'Pediatriya',
    'Xalqaro',
  ],
  kafedra: [
    "O'zbek va xorijiy tillar",
    'Lotin tili, pedagogika va psixologiya',
    'Kommunal va mehnat gigiyenasi',
    'Ovqatlanish, bolalar va o’smirlar gigienasi',
    'Preventiv tibbiyot asoslari, amoat salomatligi, jismoniy tarbiya va sport',
    'Epidemiologiya va yuqumli kasalliklar, hamshiralik ishi',
    'Mikrobiologiya, virusologiya va immunologiya',
    'Biotibbiyot muhandisligi',
    'Ichki kasallalliklar propedevtikasi',
    'Terapiya yo’nalishidagi fanlar',
    'Patologik fiziologiya va patologik anatomiya',
    'Travmatologiya va ortopediya',
    'Fiziologiya',
    'Normal anatomiya, operativ jarrohlik va topografik anatomiya',
    'Gospital terapiya (ftiziatriya, laboratoriya)',
    'Tibbiy va biologik kimyo',
    'Xalq tabobati va farmakologiya',
    'Ijtimoiy fanlar',
    'Urologiya va onkologiya',
    'Nevrologiya va psixiatriya',
    'Pediatriya',
    'Stomatologiya va otoloringologiya',
    'Biofizika va axborot texnologiyalari',
    'Gistologiya va biologiya',
    'USMLE',
    'Akusherlik va ginekologiya',
    'Umumiy jarrohlik',
    'Fakultet va gospital jarrohlik',
  ],
};

//ferpi sharqshunoslik///////////
export const environment = {
  otm: 'tsuos',
  firebase: {
    email: 'fjsti0tsnqb@gmail.com',
    p: 'asdf@rfgf34$',
  },
  a: {
    email: 'O.A@',
    p: 'b!',
  },
  production: true,
  http_auth: 'JFfd473fINU4sdfafW',
  otm_name: "Ta'lim sifatini nazorat qilish bo'limi",
  firebaseConfig: {
    // pvorey
    apiKey: 'AIzaSyCdXdbQrCOcdXI183VIcmetut_dCKG5dOU',
    authDomain: 'fjsti-c50ff.firebaseapp.com',
    projectId: 'fjsti-c50ff',
    storageBucket: 'fjsti-c50ff.appspot.com',
    messagingSenderId: '382705767044',
    appId: '1:382705767044:web:48527a1aeb7e8674c4e8d1',
    measurementId: 'G-L4NGYJKBMN',
  },
  http: {
    socket: {
      path: 'ws://ferpi.tsnqb.uz/socket.io',
    },
    editArea: {
      path: 'https://kpi.tsuos.uz/api/system/area',
    },
    addArea: {
      path: 'https://kpi.tsuos.uz/api/system/area',
    },
    deleteArea: {
      path: 'https://kpi.tsuos.uz/api/system/area',
    },
    removeDepartment: {
      path: 'https://kpi.tsuos.uz/api/system/department',
    },
    editDepartment: {
      path: 'https://kpi.tsuos.uz/api/system/department',
    },
    getDepartmentAArea: {
      path: 'https://kpi.tsuos.uz/api/system/getAll',
    },
    addDepartment: {
      path: 'https://kpi.tsuos.uz/api/system/department',
    },
    userRegister: {
      path: 'https://kpi.tsuos.uz/api/auth/registration',
    },
    recoverPass: {
      path: 'https://kpi.tsuos.uz/api/mailing/send-mail',
    },
    allRoles: {
      path: 'https://kpi.tsuos.uz/api/auth/login',
    },
    removeRole: {
      path: 'https://kpi.tsuos.uz/api/auth/login',
    },
    setRole: {
      path: 'https://kpi.tsuos.uz/api/auth/login',
    },
    removeTeacher: {
      path: 'https://kpi.tsuos.uz/api/auth/login',
    },
    allUsers: {
      path: 'https://kpi.tsuos.uz/api/users/all',
    },
    updateUser: {
      path: 'https://kpi.tsuos.uz/api/auth/login',
    },
    deleteUser: {
      path: 'https://kpi.tsuos.uz/api/auth/login',
    },
    login: {
      path: 'https://kpi.tsuos.uz/api/auth/login',
    },
    autologin: {
      path: 'https://kpi.tsuos.uz/api/auth/autologin',
    },
    get: {
      //https://tsnqb.ferpi.uz/db_pvorey_test //https://2shahar.uz/tsnqb/get_data.php
      path: 'https://kpi.tsuos.uz/db/2024/get_data.php',
      file: 'pvorey.txt',
    },
    delete_only_inform_file: {
      path: 'https://kpi.tsuos.uz/db/2024/delete_only_inform_file.php',
    },
    getFieldsInform: {
      path: 'https://kpi.tsuos.uz/db/2024/get_fields_inform.php',
    },
    setInc: {
      path: 'https://kpi.tsuos.uz/db/2024/set_data_inc.php',
      file: 'pvorey.txt',
    },
    del: {
      path: 'https://kpi.tsuos.uz/db/2024/del_data.php',
      file: 'pvorey.txt',
    },
    setIncDelUpd: {
      path: 'https://kpi.tsuos.uz/db/2024/set_inc_del_upd.php',
      file: 'pvorey.txt',
    },
    remDocDownGrade: {
      path: 'https://kpi.tsuos.uz/db/2024/rem_doc_with_down_grade.php',
    },
    googleScholarCited: {
      path: 'https://kpi.tsuos.uz/db/2024/google_scholar.php',
    },
    googleScholarCitedAll: {
      path: 'https://kpi.tsuos.uz/db/2024/google_scholar_all.php',
    },
    setNewPvo: {
      path: 'https://kpi.tsuos.uz/db/2024/set_new_pvo.php',
    },
    removePvo: {
      path: 'https://kpi.tsuos.uz/db/2024/remove_pvo.php',
    },
    controlIndexStatus: {
      path: 'https://kpi.tsuos.uz/db/2024/control_index_status.php',
    },
    createAnnounce: {
      path: 'https://kpi.tsuos.uz/db/2024/create_announce.php',
    },
    getAnnounces: {
      path: 'https://kpi.tsuos.uz/db/2024/get_announces.php',
    },
    removeAnnounce: {
      path: 'https://kpi.tsuos.uz/db/2024/remove_announce.php',
    },
    editUserAccaunt: {
      path: 'https://kpi.tsuos.uz/db/2024/edit_user_account.php',
    },
    createIndex: {
      path: 'https://kpi.tsuos.uz/db/2024/create_index.php',
    },
  },
  tizim: [
    {
      fakultet: 'Sharq filologiyasi va tarjimashunoslik fakulteti',
      kafedralar: [
        'Arab filologiyasi kafedrasi',
        'Eron-afg‘on filologiyasi kafedrasi',
        'Janubiy va Janubiy Sharqiy Osiyo tillari kafedrasi',
        'Urdu tili, adabiyoti, Pokiston madaniyati va tarixi',
        'Mumtoz filologiya va adabiy manbashunoslik kafedrasi',
        'Tarjimashunoslik va xalqaro jurnalistika kafedrasi',
        'Sharq mamlakatlari adabiyoti va qiyosiy adabiyotshunoslik kafedrasi',
        'Nazariy va amaliy tilshunoslik',
      ],
    },
    {
      fakultet: 'Sharq sivilizatsiyasi va tarix fakulteti',
      kafedralar: [
        'Sharq mamlakatlari tarixi va antropologiyasi kafedrasi',
        'Markaziy Osiyo xalqlari tarixi va etnologiyasi kafedrasi',
        'Sharq falsafasi va madaniyati kafedrasi',
        'Pedagogika va psixologiya kafedrasi',
        'Manbashunoslik va tasavvuf germenevtikasi kafedrasi',
      ],
    },
    {
      fakultet:
        'Sharq mamlakatlari iqtisodiyoti, siyosati va turizm fakulteti ',
      kafedralar: [
        'Sharq mamlakatlari iqtisodiyoti kafedrasi',
        'Sharq mamlakatlari siyosati va xalqaro munosabatlar kafedrasi',
        'Tashqi iqtisodiy faoliyat va turizm kafedrasi',
        'Matematika va umumiqtisodiy fanlar kafedrasi',
      ],
    },
    {
      fakultet: 'Xitoyshunoslik fakulteti',
      kafedralar: [
        'Xitoy filologiyasi kafedrasi',
        'Xitoy tarixi, madaniyati, siyosati va iqtisodiyoti kafedrasi',
      ],
    },
    {
      fakultet: 'Koreyashunoslik fakulteti ',
      kafedralar: [
        'Koreys filologiyasi kafedrasi',
        'Koreya tarixi, madaniyati, siyosati va iqtisodiyoti kafedrasi',
      ],
    },
    {
      fakultet: 'Yaponshunoslik fakulteti',
      kafedralar: [
        'Yapon filologiyasi kafedrasi',
        'Yaponiya tarixi, madaniyati, siyosati va iqtisodiyoti kafedrasi',
      ],
    },
    {
      fakultet: 'Turkshunoslik fakulteti',
      kafedralar: [
        'Turk filologiyasi kafedrasi',
        'Turkiy xalqlar tarixi, madaniyati, siyosati va iqtisodiyoti kafedrasi',
        'O‘zbek tili va adabiyoti kafedrasi',
        'G‘arb tillari kafedrasi',
      ],
    },
  ],
  grastud: 556,
  fakultet: [
    'Sharq filologiyasi va tarjimashunoslik fakulteti',
    'Sharq sivilizatsiyasi va tarix fakulteti',
    'Sharq mamlakatlari iqtisodiyoti, siyosati va turizm fakulteti ',
    'Xitoyshunoslik fakulteti',
    'Koreyashunoslik fakulteti ',
    'Yaponshunoslik fakulteti',
    'Turkshunoslik fakulteti',
  ],
  kafedra: [
    'Arab filologiyasi kafedrasi',
    'Eron-afg‘on filologiyasi kafedrasi',
    'Janubiy va Janubiy Sharqiy Osiyo tillari kafedrasi',
    'Urdu tili, adabiyoti, Pokiston madaniyati va tarixi',
    'Mumtoz filologiya va adabiy manbashunoslik kafedrasi',
    'Tarjimashunoslik va xalqaro jurnalistika kafedrasi',
    'Sharq mamlakatlari adabiyoti va qiyosiy adabiyotshunoslik kafedrasi',
    'Nazariy va amaliy tilshunoslik',
    'Sharq mamlakatlari tarixi va antropologiyasi kafedrasi',
    'Markaziy Osiyo xalqlari tarixi va etnologiyasi kafedrasi',
    'Sharq falsafasi va madaniyati kafedrasi',
    'Pedagogika va psixologiya kafedrasi',
    'Manbashunoslik va tasavvuf germenevtikasi kafedrasi',
    'Sharq mamlakatlari iqtisodiyoti kafedrasi',
    'Sharq mamlakatlari siyosati va xalqaro munosabatlar kafedrasi',
    'Tashqi iqtisodiy faoliyat va turizm kafedrasi',
    'Matematika va umumiqtisodiy fanlar kafedrasi',
    'Xitoy filologiyasi kafedrasi',
    'Xitoy tarixi, madaniyati, siyosati va iqtisodiyoti kafedrasi',
    'Koreys filologiyasi kafedrasi',
    'Koreya tarixi, madaniyati, siyosati va iqtisodiyoti kafedrasi',
    'Yapon filologiyasi kafedrasi',
    'Yaponiya tarixi, madaniyati, siyosati va iqtisodiyoti kafedrasi',
    'Turk filologiyasi kafedrasi',
    'Turkiy xalqlar tarixi, madaniyati, siyosati va iqtisodiyoti kafedrasi',
    'O‘zbek tili va adabiyoti kafedrasi',
    'G‘arb tillari kafedrasi',
  ],
};
