import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from './../../../environments/environment.prod';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(private http: HttpClient) {}

  getDoc(content) {
    return this.http.post(environment.http.get.path, {
      http_auth: environment.http_auth,
      file_path: environment.http.get.file,
      content: content,
    });
  }
  recoverPass(email) {
    const params = new HttpParams().set('email', email);
    return this.http
      .get(environment.http.recoverPass.path, { params })
      .toPromise();
  }
  setRole(props: any) {
    return this.http.post(environment.http.setRole.path, props).toPromise();
  }
  removeRole(roleData: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: roleData,
    };
    return this.http
      .delete(environment.http.removeRole.path, httpOptions)
      .toPromise();
  }
  removeTeacher(file_name: any) {
    return this.http.delete(
      environment.http.removeTeacher.path + '/' + file_name
    );
  }
  getAllRoles = () => {
    return this.http.get(environment.http.allRoles.path).toPromise();
  };
  getAllUsers = () => {
    return this.http.get(environment.http.allUsers.path).toPromise();
  };
  updateUser(props: any) {
    return this.http.put(environment.http.updateUser.path, props).toPromise();
  }
  deleteUser(props: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: props,
    };
    return this.http
      .delete(environment.http.deleteUser.path, httpOptions)
      .toPromise();
  }
  createUser(user: any) {
    return this.http.post(environment.http.userRegister.path, user).toPromise();
  }
  getDepartmentAArea() {
    return this.http.get(environment.http.getDepartmentAArea.path).toPromise();
  }
  addDepartment(props: any) {
    return this.http
      .post(environment.http.addDepartment.path, props)
      .toPromise();
  }
  editDepartment(department: any) {
    return this.http
      .put(environment.http.editDepartment.path, department)
      .toPromise();
  }
  removeArea(id: number) {
    return this.http
      .delete(environment.http.deleteArea.path + '/' + id)
      .toPromise();
  }
  editArea(area: any) {
    return this.http.put(environment.http.editArea.path, area).toPromise();
  }
  addArea(area: any) {
    return this.http.post(environment.http.addArea.path, area).toPromise();
  }
  removeDepartment(id: number) {
    return this.http
      .delete(environment.http.removeDepartment.path + '/' + id)
      .toPromise();
  }
  getFieldsInform() {
    return this.http.post(environment.http.getFieldsInform.path, {
      http_auth: environment.http_auth,
      data: 'data',
    });
  }
  setDocInc(inc_path, obj_path, obj) {
    return this.http.post(environment.http.setInc.path, {
      http_auth: environment.http_auth,
      file_path: environment.http.setInc.file,
      inc_path: inc_path,
      obj_path: obj_path,
      obj: obj,
    });
  }
  delDoc(obj_path) {
    return this.http.post(environment.http.del.path, {
      http_auth: environment.http_auth,
      file_path: environment.http.del.file,
      obj_path: obj_path,
    });
  }
  setIncDelUpd(
    inc_path,
    obj_path,
    obj,
    del_path,
    inc_pvogrd_path,
    inc_pvogrd_obj
  ) {
    console.log({
      inc_path,
      obj_path,
      obj,
      del_path,
      inc_pvogrd_path,
      inc_pvogrd_obj,
    });
    return this.http.post(environment.http.setIncDelUpd.path, {
      http_auth: environment.http_auth,
      file_path: environment.http.setIncDelUpd.file,
      inc_path: inc_path,
      obj_path: obj_path,
      obj: obj,
      del_path: del_path,
      inc_pvogrd_path: inc_pvogrd_path,
      inc_pvogrd_obj: inc_pvogrd_obj,
    });
  }
  delete_only_inform_file(indexId, data_added_id) {
    return this.http.post(environment.http.delete_only_inform_file.path, {
      http_auth: environment.http_auth,
      index_id: indexId,
      data_added_id: data_added_id,
    });
  }
  autologin() {
    return this.http.get(environment.http.autologin.path).toPromise();
  }
  login(cridentials: any) {
    return this.http.post(environment.http.login.path, cridentials).toPromise();
  }
  remDocDownGrade(pvo_added_id, indexId, data_added_id, grade) {
    return this.http.post(environment.http.remDocDownGrade.path, {
      http_auth: environment.http_auth,
      pvo_added_id: pvo_added_id,
      index_id: indexId,
      data_added_id: data_added_id,
      grade: grade,
    });
  }
  setNewPvo(new_pvo) {
    return this.http.post(environment.http.setNewPvo.path, {
      http_auth: environment.http_auth,
      new_pvo: new_pvo,
    });
  }
  removePvo(pvo_added_id) {
    return this.http.post(environment.http.removePvo.path, {
      http_auth: environment.http_auth,
      pvo_added_id: pvo_added_id,
    });
  }
  createAnnounce(announce) {
    return this.http.post(environment.http.createAnnounce.path, {
      http_auth: environment.http_auth,
      announce: announce,
    });
  }
  getAnnounces() {
    return this.http.post(environment.http.getAnnounces.path, {
      http_auth: environment.http_auth,
      data: 'data',
    });
  }
  controlIndexStatus(index_data) {
    return this.http.post(environment.http.controlIndexStatus.path, {
      http_auth: environment.http_auth,
      index_data: index_data,
    });
  }
  removeAnnounce(announce_id) {
    return this.http.post(environment.http.removeAnnounce.path, {
      http_auth: environment.http_auth,
      announce_id: announce_id,
    });
  }
  editUserAccaunt(pvo_added_id, new_data) {
    return this.http.post(environment.http.editUserAccaunt.path, {
      http_auth: environment.http_auth,
      pvo_added_id: pvo_added_id,
      new_data: new_data,
    });
  }
  createIndex(id, new_index) {
    return this.http.post(environment.http.createIndex.path, {
      http_auth: environment.http_auth,
      id: id,
      new_index: new_index,
    });
  }
  testtest(path) {
    return this.http.post(path, []);
  }
  googscho_upd_cit(
    url,
    old_citation,
    pvo_added_id,
    field_added_id,
    new_citiation
  ) {
    // googscho_upd_cit(url){
    return this.http.post(environment.http.googleScholarCited.path, {
      http_auth: environment.http_auth,
      url: url,
      old_citation: old_citation,
      pvo_added_id: pvo_added_id,
      field_added_id: field_added_id,
      new_citiation: new_citiation,
    });
  }
  googscho_upd_cit_all(url, old_citation, pvo_added_id, field_added_id) {
    return this.http.post(environment.http.googleScholarCitedAll.path, {
      http_auth: environment.http_auth,
      url: url,
      old_citation: old_citation,
      pvo_added_id: pvo_added_id,
      field_added_id: field_added_id,
    });
  }
  testtest2() {
    return this.http.post('https://tsnqb.ferpi.uz/db_pvorey/test.php', {
      sas: 'Salom',
    });
  }
}
