<!-- Container-fluid starts-->
<div class="container-fluid" *ngIf="!tmp.loading">
    <div class="card">
        <div class="card-header">
            <h5>O‘qituvchilar ma'lumotlari</h5>
        </div>
        <div class="card-body">
            <div id="batchDelete" class="category-table custom-datatable transcation-datatable user-image">
                <div class="btn-popup pull-right">
                    <a class="btn btn-primary" [routerLink]="'/users/create-user'">Foydalanuvchi yaratish</a>
                </div>
                <div class="btn-popup pull-right">
                        <button type="button" class="btn btn-primary" (click)="exportexcel()">Excelga ko'chirib olish</button>
                </div>
                <div class="btn-popup pull-right">
                        <select class="form-control digits" (change)="changePerPage($event)" id="exampleFormControlSelect43">
                            <option default value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                </div>
                <div class="table-responsive">
                    <ng2-smart-table 
                        id="excelTable" 
                        [settings]="settings" 
                        [source]="user_list"
                        (deleteConfirm)="onDeleteConfirm($event)"
                        (editConfirm)="onEditConfirm($event)"
                    ></ng2-smart-table>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="lds-wrap" *ngIf="tmp.loading">
    <div class="lds-dual-ring"></div>
</div>
<!-- Container-fluid Ends-->